import styled from 'styled-components'

export const SalespeopleContainer = styled.div`
    .value-center {
        background-color: var(--green-primary);
        border-radius: 1rem;
        border: none;
        color: var(--black-background);
        font-size: 1.7rem;
        font-weight: 800;
        text-align: center;

        display: block;

        max-width: 15.2rem;
        max-height: 3.6rem;
        padding: 1rem 1.6rem .6rem;
        margin: 0 auto 3.2rem;
    }

    /* Salespeople */

    .filters-container-salespeople {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: .5rem;
        align-items: center;
        max-width: 90rem;
        margin: 0 auto;
        @media (max-width: 1200px) {
            max-width: 780rem;
        }
        @media (max-width: 1100px) {
            display: flex;
            flex-wrap: wrap;
        }
        & > button {
            background-color: var(--black-background);
            border: 2px solid var(--green-primary);
            border-radius: 10px;
            color: #fff;
            font-weight: 800;
            font-size: 1.2rem;
            line-height: 1.2rem;
            text-align: center;
            vertical-align: middle;

            height: 3.6rem;
            padding: 1rem 1.6rem .6rem;
            cursor: pointer;
            &:hover,
            &:focus {
                outline: none;
                box-shadow: 0 0 0 1.5px var(--green-primary);
            }
        }
    }

    /* Tr Salespeople */
    .tr-salespeople {
        tr {
            display: grid;
            grid-template-columns: 2fr 2fr 3fr repeat(5, 2fr) 2fr;
            text-align: center;
            align-items: center;
            
            max-width: 92rem;
        }
        .tr-salespeople-background {
            margin: .4rem 0;
            td {
                word-break: break-word;
            }
            .icons {
                display: flex;
                justify-content: space-around;
                img, a {
                    margin: 0 .8rem;
                    cursor: pointer;
                }
            }
        }
    }
`

interface IModalContainerProps {
    isShow: boolean
}

export const ModalContainer = styled.div<IModalContainerProps>`
    background: var(--black-background);
    border: 1px solid var(--green-primary);
    border-radius: 1rem;
    box-sizing: border-box;
    color: var(--black-background);
    display: ${(props) => (props.isShow ? 'block' : 'none')};

    padding: 1.6rem 3.2rem;

    position: absolute;
    position:fixed;
    left: 50%;
    top: 50%;
    width: 66rem;
    height: 52rem;
    margin-left: -33rem;
    margin-top: -26rem;
    @media (max-width: 640px) {
        width: 50rem;
        height: 48rem;
        margin-top: -24rem;
        margin-left: -25rem;
    }
    @media (max-width: 500px) {
        max-width: 90vw;
        height: 96vh;
        margin-left: -45vw;
        margin-top:-48vh;
        z-index: 10;
        overflow-y: auto;
    }
    @media (max-width: 370px) {
        min-height: 90vh;
        margin-top: -48vh;
    }
    .button-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    #close {
        float: right;
    }
    .modal-container {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 20px;
        align-items: center;
        justify-content: center;

        @media (max-width: 640px) {
            grid-template-columns: 18rem 22rem;
            gap: 2rem;
        }
        @media (max-width: 500px) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            max-height: 120%;
            width: 88vw;
            overflow-y: auto;
        }
        .grid-left {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            height: 80%;
            img {
                max-width: 200px;
            }
            @media (max-width: 640px) {
                img {
                    max-width: 16rem;
                }
            }
            @media (max-width: 500px) {
                min-height: 30rem;
            }
            @media (max-width: 370px) {
                img {
                    max-width: 12rem;
                }
            }
            button {
                background: var(--green-primary);
                border: 1px solid var(--green-primary);
                border-radius: 1rem;
                font-weight: 800;
                font-size: 1.8rem;
                line-height: 1.9rem;
                text-align: center;

                width: 17.2rem;
                height: 4rem;
                cursor: pointer;
                &:hover,
                &:focus {
                    outline: none;
                    box-shadow: 0 0 0 2px var(--green-primary);
                }
                @media (max-width: 640px) {
                    font-size: 1.6rem;
                    line-height: 1.6rem;
                    padding: 1.2rem 0;

                    width: 12rem;
                }
                @media (max-width: 370px) {
                    font-size: 1.6rem;
                    line-height: 1.6rem;

                    width: 12rem;
                    height: 3.6rem;
                    left: 13rem;
                }
            }
            h3 {
                background: #fff;
                border: 1px solid #fff;
                border-radius: 10px;
                color: var(--black-background);
                font-weight: 800;
                font-size: 1.8rem;
                line-height: 1.8rem;
                vertical-align: middle;
                text-align: center;

                width: 17.2rem;
                height: 4rem;
                padding: 1.4rem 0;
                margin-bottom: 1rem;
            }
            .actions-button {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 8px;
                button {
                    border-radius: 1rem;
                    font-weight: 800;
                    font-size: 1.4rem;
                    line-height: 1.4rem;
                    text-align: center;

                    max-width: 80px;
                    height: 5rem;
                    cursor: pointer;
                }
                #approve {
                    background-color: var(--green-primary);
                    border: 1px solid var(--green-primary);
                    &:hover,
                    &:focus {
                        outline: none;
                        box-shadow: 0 0 0 2px var(--green-primary);
                    }
                }
                #decline {
                    background-color: #FF0000;
                    border: 1px solid #FF0000;
                    &:hover,
                    &:focus {
                        outline: none;
                        box-shadow: 0 0 0 2px #FF0000;
                    }
                }

            }
            .actions-decline {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items:center;
                #isdecline {
                    background-color: #c4c4c4;
                    border: 1px solid #c4c4c4;
                    width: 100%;

                    margin: 1.6rem 0;
                    &:hover,
                    &:focus {
                        outline: none;
                        box-shadow: 0 0 0 2px #c4c4c4;
                    }
                }
                textarea {
                    min-height: 14rem;
                    font-family: inherit;
                    resize: none;
                    border-radius: 1rem;
                    padding: 1rem 0.5rem;
                }
            }
        }
        .personal-data {
            display: flex;
            flex-direction: column;
            p {
                background: #fff;
                border-radius: 1rem;
                font-weight: 800;
                font-size: 1.8rem;
                line-height: 1.9rem;

                padding: 1.2rem;
                margin-bottom: 1.6rem;
                @media (max-width: 640px) {
                    font-size: 1.6rem;
                    line-height: 1.6rem;

                    padding: 1rem;
                    margin-bottom: 12px;
                }
                @media (max-width: 500px) {
                    font-size: 1.4rem;
                    line-height: 1.4rem;

                    padding: .8rem;
                    margin-bottom: 1rem;
                }
            }
            label {
                color: #fff;
                font-weight: 800;
                font-size: 1.6rem;
                line-height: 2.4rem;
                @media (max-width: 640px) {
                    font-size: 1.4rem;
                    line-height: 2rem;
                }
                @media (max-width: 370px) {
                    font-size: 1.2rem;
                    line-height: 1.8rem;
                }
            }
        }
    }
    .back-or-close {
        background-color: transparent;
        border: none;
        color: #fff;
        font-size: 1.3rem;
        font-weight: 800;

        cursor: pointer;
        outline: none;
    }
    .photo-list-team {
        max-width: 3rem;
    }
    #back {
        background: var(--black-background);
        border: 1px solid #fff;
        border-radius: 1rem;
        color: #fff;
        font-weight: 800;
        font-size: 1.8rem;
        line-height: 1.9rem;

        float: right;
        position: absolute;
        right: 0;
        bottom: 0;
        
        width: 17.2rem;
        height: 5.1rem;
        margin: 0 6rem 1rem 0;
        cursor: pointer;
        transition: 200ms;
        @media (max-width: 640px) {
            width: 14.4rem;
            height: 4rem;
            right: 0;
        }
        @media (max-width: 500px) {
            display: none;
        }
        &:hover,
        &:focus {
            color: var(--green-primary);
            outline: none;
            border: 1px solid var(--green-primary);
            box-shadow: 0 0 0 1px var(--green-primary);
        }
    }
`;
