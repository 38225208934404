import styled from 'styled-components'

interface ISalespeopleAndStudentsContainerProps {
    isAdd: boolean
}

export const SalespeopleAndStudentsContainer = styled.div<ISalespeopleAndStudentsContainerProps>`
    .value-center {
        margin: 2.4rem 0 1.6rem;
        text-align: center;

        span {
            background-color: var(--black-background);
            border: 2px solid var(--green-primary);
            border-radius: 1rem;
            color: #fff;
            font-weight: 800;
            font-size: 1.2rem;
            line-height: 2.2rem;
            text-align: center;

            max-width: 18rem;
            max-height: 3.6rem;
            padding: 1.1rem 3.2rem .6rem;
            margin: 2px;
            @media (max-width: 600px) {
                height: 4rem;
            }
        }
    }

    /* Salespeople */

    .filters-container-salespeople {
        display: grid;
        grid-template-columns: 1fr 1fr 1.5fr 1fr 1fr 1fr;
        gap: .5rem;
        align-items: center;
        max-width: 86rem;
        margin: 0 auto;
        @media (max-width: 1100px) {
            display: flex;
            flex-wrap: wrap;
        }
        
        & > button {
            background-color: var(--black-background);
            border: 2px solid var(--green-primary);
            border-radius: 1rem;
            color: #fff;
            font-weight: 800;
            font-size: 1rem;
            line-height: 2.2rem;
            text-align: center;
            text-transform: uppercase;
            vertical-align: middle;

            height: 3.6rem;
            padding: .8rem 1.6rem .6rem;
            cursor: pointer;
            &:hover,
            &:focus {
                outline: none;
                box-shadow: 0 0 0 1.5px var(--green-primary);
            }
        }

        .selected {
            background-color: var(--green-primary);
            color: var(--black-background);
            font-weight: 800;
            &:hover,
            &:focus {
                box-shadow: none;
            }
        }
    }
    /* Table */
    tr {
        display: grid;
        grid-template-columns: 2fr 3fr repeat(6, 2fr);
        gap: 1rem;
        text-align: center;
        align-items: center;

        button {
            background-color: var(--black-background);
            border: 1px solid var(--green-primary);
            border-radius: 1rem;
            color: #fff;
            font-weight: 800;
            font-size: 1rem;
            line-height: 1rem;
            text-align: center;
            text-transform: uppercase;
            vertical-align: middle;

            max-width: 18rem;
            height: 2rem;
            padding: .6rem 2rem .4rem;
            margin: 2px;
            cursor: pointer;
            &:hover,
            &:focus {
                outline: none;
                box-shadow: 0 0 0 1px var(--green-primary);
            }
        }
    }

    th {
        button {
            background-color: var(--black-background);
            border: 1px solid var(--green-primary);
            border-radius: 1rem;
            color: #fff;
            font-weight: 800;
            font-size: 1rem;
            line-height: 1rem;
            text-align: center;
            text-transform: uppercase;
            vertical-align: middle;

            max-width: 18rem;
            height: 2rem;
            padding: .6rem 2rem .4rem;
            margin: 2px;
            cursor: pointer;
            &:hover,
            &:focus {
                outline: none;
                box-shadow: 0 0 0 1px var(--green-primary);
            }
        }
    }

    /* Students */

    .filters-container-students {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 3fr;
        gap: .5rem;
        align-items: center;
        
        max-width: 86rem;
        margin: 0 auto;
        position: relative;
        @media (max-width: 1100px) {
            display: flex;
            flex-wrap: wrap;
        }
        .button-right {
            position: absolute;
            right: 0;
            @media (max-width: 1100px) {
                position: static;
            }
        }
        & > button, .button-right button {
            background-color: var(--black-background);
            border: 2px solid var(--green-primary);
            border-radius: 1rem;
            color: #fff;
            font-weight: 800;
            font-size: 1rem;
            line-height: 2.2rem;
            text-align: center;
            text-transform: uppercase;
            vertical-align: middle;

            height: 3.6rem;
            padding: .8rem 1.6rem .6rem;
            cursor: pointer;
            &:hover,
            &:focus {
                outline: none;
                box-shadow: 0 0 0 1.5px var(--green-primary);
            }
        }
        .selected {
            background-color: var(--green-primary);
            color: var(--black-background);
            &:hover,
            &:focus {
                box-shadow: none;
            }
        }
        #ok {
            background-color: var(--green-primary);
            border: none;

            width: 3.6rem;
            height: 3.6rem;
            padding: 0;
            margin: 0;
            margin-right: 1rem;
            &:hover,
            &:focus {
                outline: none;
                box-shadow: 0 0 0 1px var(--green-primary);
            }
        }
        #cancel {
            background-color: #FF0000;
            border: none;

            width: 3.6rem;
            height: 3.6rem;
            padding: 0;
            margin: 0;
            &:hover,
            &:focus {
                outline: none;
                box-shadow: 0 0 0 1px #FF0000;
            }
        }
    }

    .new-student {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        p {
            color: #646464;
            font-size: 1rem;
            font-weight: 800;
        }
        .month {
            margin-left: 1.6rem;
        }
        button {
            background-color: transparent;
            border: none;
            padding: 2px;
            margin: 0;
            margin-left: 4px;
            &:hover,
            &:focus {
                box-shadow: none;
            }
        }
    }

    .input-new-student {
        display: grid;
        grid-template-columns:  1fr 1fr 1fr 1fr 1fr;
        align-items: center;
        justify-items: center;
        margin: .4rem 0;
        p {
            color: #646464;
            font-size: 1rem;
            font-weight: 800;
        }
        input {
            border: none;
            color: var(--black-background);
            font-size: 1.6rem;
            
            max-width: 8rem;
            outline: none;
            &::placeholder {
                color: #646464;
                font-size: 1rem;
                font-weight: 800;
                text-align: center;
            }
        }
        select {
            border: none;
            background-color: #fff;
            text-align: center;
            outline: none;
        }
    }
    /* Tr Students */
    .tr-students {
        tr {
            display: grid;
            grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
            text-align: center;
            align-items: center;
        }
    }
`

interface IModalContainerProps {
    isOpenModal: boolean
}

export const ModalContainer = styled.div<IModalContainerProps>`
    display: ${({ isOpenModal }) => isOpenModal ? 'block' : 'none'};
    
    background: var(--black-background);
    border: 1px solid var(--green-primary);
    border-radius: 1rem;
    color: var(--black-background);
    
    padding: 3.2rem 5.2rem;

    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    max-width: 60rem;
    max-height: 46rem;

    #close {
        background-color: transparent;
        border: none;
        position: absolute;
        top: .4rem;
        right: 0;

        outline: none;
        cursor: pointer;
    }
    @media (max-width: 640px) {
        width: 50rem;
        margin-left:-25rem;
    }
    @media (max-width: 500px) {
        max-width: 90vw;
        min-height: 90vh;
        margin-left: -45vw;
        margin-top:-45vh;
        z-index: 10;
    }
    @media (max-width: 400px) {
        padding: 2.4rem 3.2rem;
    }
    @media (max-width: 370px) {
        min-height: 90vh;
        margin-top: -48vh;
    }

    .modal-container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1.6rem;

        @media (max-width: 500px) {
            display: flex;
            flex-direction: column;
            
            min-width: 100%;
            max-height: 80vh;
            overflow-y: auto;
        }

        .pay-status {
            background-color: var(--black-background);
            border: 2px solid var(--green-primary);
            border-radius: 1rem;
            color: #fff;
            font-weight: 800;
            font-size: 1rem;
            line-height: 2.2rem;
            text-align: center;
            text-transform: uppercase;
            vertical-align: middle;

            height: 3.6rem;
            padding: .8rem 1.6rem .6rem;
            cursor: pointer;
            &:hover,
            &:focus {
                outline: none;
                box-shadow: 0 0 0 1.5px var(--green-primary);
            }
        }

        .selected {
            background-color: var(--green-primary);
            color: var(--black-background);
            font-weight: 800;
            &:hover,
            &:focus {
                box-shadow: none;
            }
        }

        .confirm {
            background: var(--green-primary);
            border: 1px solid var(--green-primary);
            border-radius: 1rem;
            color: var(--black-background);
            font-weight: 800;
            
            height: 4rem;
            min-height: 4rem;

            width: 23rem;

            outline: none;            
            cursor: pointer;

            @media (max-width: 640px) {
                position: relative;
            }

            @media (max-width: 500px) {
                width: 100%;
            }

            &:hover,
            &:focus {
                outline: none;
                box-shadow: 0 0 0 1px var(--green-primary);
            }
        }
    }
`
