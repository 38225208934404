import styled from 'styled-components'

export const CourseContainer = styled.div`
    .course-image {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 15rem;
        margin: 0 auto;
        h1 {
            color: var(--black-background);
            font-weight: 800;
            font-size: 2.4rem;
            line-height: 2.4rem;
        }
        h2 {
            color: var(--black-background);
            font-weight: 800;
            font-size: 2rem;
            line-height: 2rem;
        }
    }
    .search-and-add {
        display: flex;
        justify-content: flex-end;

        margin: 3.2rem 0 1.6rem;
        button {
            background-color: var(--black-background);
            border: 2px solid var(--green-primary);
            border-radius: 1rem;
            color: #fff;
            font-weight: 800;
            font-size: 1rem;
            line-height: 1.2rem;
            text-align: center;
            text-transform: uppercase;
            vertical-align: middle;

            height: 3.6rem;
            margin-left: .8rem;
            padding: 1rem 1.6rem .6rem;
            cursor: pointer;
            @media (max-width: 600px) {
                min-height: 4rem;
            }
            &:hover,
            &:focus {
                outline: none;
                box-shadow: 0 0 0 1.5px var(--green-primary);
            }
        }
    }
    ul {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto;
        gap: .8rem;

        max-width: 140rem;
        max-height: 40rem;
        padding: .8rem;
        overflow: auto;
        ::-webkit-scrollbar-track {
            background-color: #c4c4c4;
            border-radius: 2rem;
        }
        ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            @media (max-width: 800px) {
                width: 4px;
                height: 4px;
            }
        }
        ::-webkit-scrollbar-thumb {
            background: #646464;
            border-radius: 2rem;
        }
    }
`

interface ICoursesProps {
    photoCourse: string
}

export const Courses = styled.li<ICoursesProps>`
    background: 
        linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0.7),
            rgba(255, 255, 255, 0.1)
        ),
        url(${props => props.photoCourse});
    /* filter: contrast(80%); //Muda o constrate */
    background-size: contain;
    background-repeat: no-repeat;
    border: 1px solid var(--black-background);
    border-radius:1.2rem;
    max-width: 29rem;
    max-height: 30rem;
    /* min-height: 100%; */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    h2 {
        margin: 2.6rem 3.2rem;
    }
    .about-course {
        background-color: var(--black-background);
        background-size: cover;
        border-radius: 1rem;
        color: #fff;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

       
        margin-top: 4rem;
        padding: 1.2rem;
        height: 11rem;
        div {
            height: 5rem;
            white-space: normal;
            text-overflow: ellipsis;
            overflow: hidden;

            p {
                font-size: 1.2rem;
                font-weight: 800;
                display: flex;
                flex-wrap: wrap;
                max-height: 50%;
                width: 100%;
            }
        }
       
        .prices {
            display: flex;
            justify-content: flex-end;
            margin-bottom: -2.5rem;
            opacity: 1;

            font-size: 1.4rem;
            font-weight: 800;
            .full-price {
                background-color: var(--black-background);

                border: 1px solid #fff;
                border-radius: 1rem;

                max-height: 3.4rem;
                padding: .6rem;
            }
            .discount-price {
                background-color: var(--green-primary);
                border: 1px solid var(--green-primary);
                border-radius: 1rem;
                color: var(--black-background);
                
                max-height: 3.4rem;
                padding: .6rem;
                margin-left: .8rem;
            }
        }
    }
`

interface IModalContainerProps {
    isOpenModal: boolean
}

export const ModalContainer = styled.div<IModalContainerProps>`
    display: ${({ isOpenModal }) => isOpenModal ? 'block' : 'none'};
    
    background: var(--black-background);
    border: 1px solid var(--green-primary);
    border-radius: 1rem;
    color: var(--black-background);
    
    padding: 3.2rem 5.2rem;

    position: fixed;
    left: 50%;
    top: 50%;

    max-width: 60rem;
    max-height: 46rem;
    margin-left:-30rem;
    margin-top:-23rem;
    #close {
        background-color: transparent;
        border: none;
        position: absolute;
        top: .4rem;
        right: 0;

        outline: none;
        cursor: pointer;
    }
    @media (max-width: 640px) {
        width: 50rem;
        margin-left:-25rem;
    }
    @media (max-width: 500px) {
        max-width: 90vw;
        min-height: 90vh;
        margin-left: -45vw;
        margin-top:-45vh;
        z-index: 10;
    }
    @media (max-width: 400px) {
        padding: 2.4rem 3.2rem;
    }
    @media (max-width: 370px) {
        min-height: 90vh;
        margin-top: -48vh;
    }
    .modal-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.6rem;

        position: relative;
        @media (max-width: 500px) {
            display: flex;
            flex-direction: column;
            
            min-width: 100%;
            max-height: 80vh;
            overflow-y: auto;
        }
        fieldset {
            border: none;
        }
        label {
            color: #fff;
            font-size: 1.2rem;
            font-weight: 800;

            margin-bottom: .6rem;
        }
        .grid-left {
            .description-container {
                margin-top: 2.6rem;
                display: flex;
                flex-direction: column;
                
                textarea {
                    background: var(--black-background);
                    border: 1px solid var(--green-primary);
                    border-radius: 1rem;
                    color: #fff;

                    height: 12rem;
                    padding: .6rem;
                    outline: none;
                    resize: none;
                    &::placeholder {
                        color: #fff;
                        font-size: 1.2rem;
                        font-weight: 800;
                    }
                    &:hover,
                    &:focus {
                        outline: none;
                        box-shadow: 0 0 0 1px var(--green-primary);
                    }
                    ::-webkit-scrollbar-track {
                        background-color: #c4c4c4;
                        border-radius: 2rem;
                    }
                    ::-webkit-scrollbar {
                        width: 6px;
                        height: 6px;
                        @media (max-width: 800px) {
                            width: 4px;
                            height: 4px;
                        }
                    }
                    ::-webkit-scrollbar-thumb {
                        background: #646464;
                        border-radius: 2rem;
                    }
                }
            }
        }
        .grid-right {
            .input-data {
                display: flex;
                flex-direction: column;
                flex: 1;
                input,
                select {
                    background: var(--black-background);
                    border: 1px solid var(--green-primary);
                    border-radius: 1rem;
                    color: #fff;

                    height: 4rem;
                    padding-left: .4rem;
                    margin-bottom: 1rem;
                    outline: none;
                    &:hover,
                    &:focus {
                        outline: none;
                        box-shadow: 0 0 0 1px var(--green-primary);
                    }
                }
                input[type=number]::-webkit-inner-spin-button, 
                input[type=number]::-webkit-outer-spin-button { 
                    -webkit-appearance: none;
                }
            }
        }
        .confirm {
            background: var(--green-primary);
            border: 1px solid var(--green-primary);
            border-radius: 1rem;
            color: var(--black-background);
            font-weight: 800;
            
            height: 4rem;
            min-height: 4rem;

            width: 23rem;
            position: absolute;
            bottom: -1rem;
            right: 0;

            outline: none;
            cursor: pointer;
            @media (max-width: 640px) {
                position: relative;
            }
            @media (max-width: 500px) {
                width: 100%;
            }
            &:hover,
            &:focus {
                outline: none;
                box-shadow: 0 0 0 1px var(--green-primary);
            }
        }
        .delete {
            background: red;
            border: 1px solid red;
            border-radius: 1rem;
            color: black;
            font-weight: 900;
            
            height: 4rem;
            min-height: 4rem;

            width: 23rem;
            position: absolute;
            bottom: 4rem;
            right: 0;

            outline: none;
            cursor: pointer;
            @media (max-width: 640px) {
                position: relative;
            }
            @media (max-width: 500px) {
                width: 100%;
            }
            &:hover,
            &:focus {
                outline: none;
                box-shadow: 0 0 0 1px red;
            }
        }
    }
`

interface IInputPhotoProps {
    imgSource: string
}

export const InputPhoto = styled.div<IInputPhotoProps>`
    width: 23.2rem;
     @media (max-width: 500px) {
        min-width: 100%;
    }
    .background-image {
        background: url(${props => props.imgSource}), var(--black-background);
        background-size: cover;
        border: 1px solid var(--green-primary);
        border-radius: 1rem;
        color: #fff;

        min-width: 100%;
        height: 18rem;
        position: relative;
        outline: none;
        cursor: pointer;
        &:hover,
        &:focus {
            outline: none;
            box-shadow: 0 0 0 1px var(--green-primary);
        }
    }
`
