import React, { useState } from 'react'

import Accordion from '../../components/Accordion'
import Input from '../../components/Input'

import Modal from './Modal'

import iconHelp from '../../Assets/icons/button-help.svg';

import { FaqCategoryEnum, IFaq, IFaqBody } from '../../models/Faq'


import { FaqContainer } from './styles'
import useDebouncedText from '../../hooks/debouncedText';
import useDataPaginator from '../../hooks/dataPaginator';
import { useGetFAQ } from '../../services/api/faq';
import { dummyData } from './data';

interface IEntityGroup {
  [key: string]: IFaqBody[]
}

const groupedByCategory = (info: IFaqBody[]) => info
  .reduce((categoriesObject: IEntityGroup, item) => ({
    ...categoriesObject,
    [item.category]: [...(categoriesObject[item.category] || []), item],
  }), {});

const FaqComponent: React.FC = () => {
  // const [listFaq, setListFaq] = useState<IFaq[]>(data)
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  // const [thisFaq, setThisFaq] = useState<IFaq | undefined>(undefined)
  const getFAQ = useGetFAQ()

  const [filterName, setFilterName] = useDebouncedText('');
  const [questionId, setQuestionId] = useState('')
  const [questionDescription, setQuestionDescription] = useState('')
  const [questionTitle, setQuestionTitle] = useState('')
  const [questionCategory, setQuestionCategory] = useState<FaqCategoryEnum>(FaqCategoryEnum.attendance)


  // const [
  //     Attendancedata,
  //     setAttendanceData,
  //     attendanceDataListLoading,
  //     checkDataListBottom,
  //   ] = useDataPaginator<IFaqBody, [string]>({
  //     filters: [filterName],
  //     storeNewData: async (filters, cursor) => {
  //       const res = await getFAQ({ 
  //         cursor: cursor as string,
  //         filter_name: filters[0],
  //       });
  //       console.log('---<<>>', res);
  //       return {
  //         nextCursor: res.cursor.afterCursor,
  //         shouldResetData: !res.cursor.beforeCursor,
  //         nextData: res.data,
  //       };
  //     },
  //   });
    const [
      data,
      setData,
      dataListLoading,
      checkDataListBottom,
    ] = useDataPaginator<IFaqBody, [string, boolean]>({
      filters: [filterName, isOpenModal],
      storeNewData: async (filters) => {
        const res = await getFAQ({ 
          filter_name: filters[0],
        });
        console.log('---<<>>', res);
        return {
          nextCursor: res.cursor.afterCursor,
          shouldResetData: !res.cursor.beforeCursor,
          nextData: res.data,
        };
      },
    });

    const handleQuestionClick = (item: {id: string, question: string, description: string, category: string}) => {
     console.log(item, 'itemm')
      setQuestionId(item.id)
      setQuestionDescription(item.description)
      setQuestionTitle(item.question)
      setQuestionCategory(item.category as FaqCategoryEnum)
      setIsOpenModal(true)
    }

  return (
    <>
      <FaqContainer>
        <div className="intro-container">
          <img src={iconHelp} alt="FAQ" />
          <h1>Central de Ajuda</h1>
        </div>
        <div className="search-and-add">
          <Input
            type="text"
            placeholder="Questão"
            onChange={(e) => setFilterName(e.target.value)}
          />
          <button type="button"
            onClick={() => setIsOpenModal(true)}>
            Adicionar
          </button>
        </div>
        {data.length ? Object.keys(groupedByCategory(data))
        .map((category: string) => (
          <><h3>{category}</h3>
            <div className="sales-attendance">
          {groupedByCategory(data)[category].map((item) => (
            <Accordion
              key={`${item.id} sales`}
              title={item.question}
              content={item.description}
              onClick={() => {
                console.log('mano???')
                handleQuestionClick(item)
              }}
            />
          ))}
            </div>
            </>
        )) : <span>Não encontramos nenhuma pergunta</span> }
      </FaqContainer>
      <Modal
        isOpenModal={!!isOpenModal}
        setIsOpenModal={setIsOpenModal}
        idFAQ={questionId}
        descriptionFAQ={questionDescription}
        questionFAQ={questionTitle}
        categoryFAQ={questionCategory}
      />
    </>
  )
}

export default FaqComponent;
