import { format, parseISO } from 'date-fns';
import React, { useState } from 'react';

import Input from '../../components/Input';
import useDataPaginator from '../../hooks/dataPaginator';
import useDebouncedText from '../../hooks/debouncedText';
import { IStudent, IStudentEnrollmentList } from '../../models/Student';
import { useGetStudentList } from '../../services/api/clients';

import { formatCurrency } from '../../services/utils/format'

import { Table } from '../../styles/GenericStyles';

interface IStudentsProps {
    isAdd: boolean
    setIsAdd: React.Dispatch<React.SetStateAction<boolean>>;
}


const Students: React.FC<IStudentsProps> = () => {

    const getStudentList = useGetStudentList();

    const [filterCourseName, setFilterCourseName] = useDebouncedText('');
    const [filterName, setFilterName] = useDebouncedText('');
    const [filterSellerName, setFilterSellerName] = useDebouncedText('');

    const [
        data,
        setData,
        dataListLoading,
        checkDataListBottom,
      ] = useDataPaginator<IStudentEnrollmentList, [string, string, string]>({
        filters: [filterCourseName,filterName, filterSellerName],
        storeNewData: async (filters, cursor) => {
          
          const res = await getStudentList({ 
            cursor: cursor as string,
            filter_course_name: filters[0],
            filter_name: filters[1],
            filter_seller_name: filters[2],
          });
          console.log('---<<>>', res);
          return {
            nextCursor: res.cursor.afterCursor,
            shouldResetData: !res.cursor.beforeCursor,
            nextData: res.data,
          };
        },
      });


    return (
        <>
            <div className="filters-container-students">
                <Input type="text" placeholder="Nome" 
                onChange={(e) => setFilterName(e.target.value)}
                />
                <Input type="text" placeholder="Curso" 
                onChange={(e) => setFilterCourseName(e.target.value)}
                />
                <Input type="text" placeholder="Vendedor"
                onChange={(e) => setFilterSellerName(e.target.value)}
                />
            </div>
            <Table onScroll={checkDataListBottom}>
                <thead className="tr-students">
                    <tr>
                        <th>Nome</th>
                        <th>CPF</th>
                        <th>Curso</th>
                        <th>Vendedor</th>
                        <th>Valor</th>
                        <th>Adesão</th>
                        {/* <th>Vencimento</th> */}
                    </tr>
                </thead>
                <tbody className="tr-students">
                    {data.map((item) => (
                        <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.document_number}</td>
                            <td>{item.sales.course.name}</td>
                            <td>{item.sales.user.name}</td>
                            <td>{formatCurrency(item.sales.course.price | 0)}</td>
                            <td>{format(parseISO(item.created_at), 'dd/MM/yyyy')}</td>
                            {/* <td>{item.expiration}</td> */}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
};

export default Students;
