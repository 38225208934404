import api, { RawHTTPResponse, getAuthHeader, PaginatedResponse, AxiosError } from '../api';
import useApiCall from '../../../hooks/apiCall';
import { IValidPaymentStatusFilter, PaymentStatusEnum } from '../../../models/Payment';
import { ITuition } from '../../../models/Tuition';

interface SellersPaymentsList {
    Request: {
        skip?: number;
        nameFilter?: string;
        status: IValidPaymentStatusFilter;
        afterDate?: Date,
        beforeDate?: Date
    };
    Response: {
        sellers: {
            id: string;
            name: string;
            bank_completed_name: string | null;
            bank: string | null;
            bank_account: string | null;
            bank_account_type: string | null;
            bank_agency: string | null;
            bank_document_number: string | null;
            balance: string;
        }[],
        total_earnings: number
    };
}

export const useSellersPaymentsList = () => useApiCall<SellersPaymentsList['Request'], SellersPaymentsList['Response']>({
    request: async ({
        skip = 0,
        nameFilter = '',
        status,
        afterDate,
        beforeDate
    }, token) => {
        const { data }: RawHTTPResponse<SellersPaymentsList['Response']> = await api.get(
            '/financial/sales/payment-history?'
                + (skip > 0 ? `skip=${skip}&` : '')
                + (nameFilter?.length > 0 ? `filter_name=${nameFilter}&` : '')
                + (status ? `filter_status=${status}&` : '')
                + (afterDate ? `after_date=${afterDate.toISOString()}&` : '')
                + (beforeDate ? `before_date=${beforeDate.toISOString()}` : ''),
            getAuthHeader(token)
        );
        return data;
    },
    errorMessage: {
        title: 'Erro na requisição',
        description: 'Não foi possível carregar os pagamentos',
    },
});

interface UpdateSellerPaymentStatus {
    Request: {
        seller_id: string,
        from_status: IValidPaymentStatusFilter,
        to_status: IValidPaymentStatusFilter
    }[];
    Response: [];
}

export const useUpdateSellersPaymentStatus = () => useApiCall<UpdateSellerPaymentStatus['Request'], UpdateSellerPaymentStatus['Response']>({
    request: async (sellers, token) => {
        const { data }: RawHTTPResponse<UpdateSellerPaymentStatus['Response']> = await api.put(
            '/financial/manager-earnings',
            { sellers },
            getAuthHeader(token)
        );
        return data;
    },
    errorMessage: {
        title: 'Erro na requisição',
        description: 'Não foi possível atualizar o status do(s) pagamento(s)',
    },
});

interface ListStudentsPayments {
    Request: {
        cursor?: string;
        nameFilter?: string;
        afterDate?: Date,
        beforeDate?: Date
    };
    Response: PaginatedResponse<ITuition>;
}

export const useListStudentsPayments = () => useApiCall<ListStudentsPayments['Request'], ListStudentsPayments['Response']>({
    request: async ({ cursor = '', nameFilter = '', afterDate, beforeDate }, token) => {
        const { data }: RawHTTPResponse<ListStudentsPayments['Response']> = await api.get(
            '/tuition?'
                + (cursor?.length > 0 ? `after_cursor=${cursor}&` : '')
                + (nameFilter?.length > 0 ? `filter_name=${nameFilter}&` : '')
                + (afterDate ? `after_date=${afterDate.toISOString()}&` : '')
                + (beforeDate ? `before_date=${beforeDate.toISOString()}` : ''),
            getAuthHeader(token)
        );
        return data;
    },
    errorMessage: {
        title: 'Erro na requisição',
        description: 'Não foi possível carregar os pagamentos de alunos',
    },
});

interface CreateStudentsPayments {
    Request: {
        login: string;
        value: number;
        tuition_month: string;
    }[];
    Response: ITuition[];
}

export const useCreateStudentsPayments = () => useApiCall<CreateStudentsPayments['Request'], CreateStudentsPayments['Response']>({
    request: async (tuitions, token) => {
        const { data }: RawHTTPResponse<CreateStudentsPayments['Response']> = await api.post(
            '/tuition',
            { tuitions: tuitions.map(t => ({ ...t, value: t.value.toString() })) },
            getAuthHeader(token)
        );
        return data;
    },
    errorMessage: {
        title: 'Erro na requisição',
        description: 'Não foi possível atualizar o status do(s) pagamento(s)',
    },
    getDynamicErrorMessage: (error: AxiosError) => {
        if (error.isAxiosError) {
            const errorMessage: string = error.response?.data?.error;
            if (errorMessage && errorMessage.startsWith('Erro na adição das mensalidades')) {
                const toastMessage = errorMessage.split(':').slice(1).join(':');
                return toastMessage;
            }
        }
        
        return '';
    }
});
