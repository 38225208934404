import React, { useState } from 'react';

import Input from '../../components/Input';
import Select from '../../components/Select'


import view from '../../Assets/icons/view.svg';

import { Table } from '../../styles/GenericStyles';
import { SalespeopleContainer } from './styles'
import { IUser, ProfileStatusEnum } from '../../models/User';
import Modal from './Modal';

import DatePicker from '../../components/DatePicker';

import useDataPaginator from '../../hooks/dataPaginatorSkip';
import useDebouncedText from '../../hooks/debouncedText';
import { useSellersList, useUpdateSellerStatus } from '../../services/api';

import { documentNumberMask } from '../../utils/masks';

import {
    format,
    parseISO,
    startOfMonth,
    endOfMonth,
    subMonths
} from 'date-fns';

const translateSellerStatus = (status: ProfileStatusEnum) => {
    if (status === ProfileStatusEnum.pending) return 'Pendente';
    if (status === ProfileStatusEnum.approved) return 'Aprovado';
    if (status === ProfileStatusEnum.reject) return 'Recusado';
};

const profileStatusSelectElList = [
    { id: "1", name: "Pendente", value: ProfileStatusEnum.pending },
    { id: "2", name: "Aprovado", value: ProfileStatusEnum.approved },
    { id: "3", name: "Recusado", value: ProfileStatusEnum.reject }
];

const Salespeople: React.FC = () => {
    const getSellersList = useSellersList();
    const updateSellerStatus = useUpdateSellerStatus();

    const [registerDateFilter, setRegisterDateFilter] = useState<Date[]>([
        subMonths(startOfMonth(new Date()), 1),
        endOfMonth(new Date())
    ]);
    const [nameFilter, setNameFilter] = useDebouncedText();
    const [emailFilter, setEmailFilter] = useDebouncedText();
    const [docFilter, setDocFilter] = useDebouncedText();
    const [statusFilter, setStatusFilter] = useState<ProfileStatusEnum | undefined>(undefined);

    const [
        sellers,
        setSellers,
        areSellersLoading,
        checkSellersListBottom
    ] = useDataPaginator<IUser, [string, string, string, Date[], ProfileStatusEnum | undefined]>({
        filters: [nameFilter, emailFilter, docFilter, registerDateFilter, statusFilter],
        storeNewData: async (filters, skip) => {
            const res = await getSellersList({
                skip,
                nameFilter: filters[0],
                emailFilter: filters[1],
                docFilter: filters[2],
                afterDate: filters[3][0],
                beforeDate: filters[3][1],
                status: filters[4]
            });

            return {
                nextSkipCounter: skip + res.length,
                hasReachedEnd: res.length === 0,
                nextData: res,
            };
        }
    });

    const [seller, setSeller] = useState<IUser>();

    const handleStatusUpdate = async (status: ProfileStatusEnum, message?: string) => {
        try {
            const updatedSeller = await updateSellerStatus({
                id: seller?.id as string,
                status
            });

            console.log('Updated Seller', { updatedSeller })
            
            setSeller(s => s && ({ ...s, status }));
    
            setSellers(ss => ss.map(s => {
                if (s.id === seller?.id) {
                    return { ...s, status };
                }
                return s;
            }));
            
            return true;
        } catch (e) {
            return false;
        }
    };

    return (
        <SalespeopleContainer>
            <span className='value-center'>Vendedores</span>
            <div className="filters-container-salespeople">
                <Input type="text" placeholder="Name" onChange={(e) => setNameFilter(e.target.value)} />
                <Input type="text" placeholder="Email" onChange={(e) => setEmailFilter(e.target.value)} />
                <Input type="text" placeholder="CPF/CNPJ" onChange={(e) => {
                    const maskedValue = documentNumberMask(e.target.value);
                    e.target.value = documentNumberMask(maskedValue);
                    setDocFilter(maskedValue);
                }} />

                <Select
                    value={profileStatusSelectElList}
                    thisForm={false}
                    onChange={value => setStatusFilter(value as ProfileStatusEnum)}
                />

                <DatePicker
                    dateRange={registerDateFilter}
                    setDateRange={setRegisterDateFilter}
                />
            </div>
            <Table onScroll={checkSellersListBottom}>
                <thead className="tr-salespeople">
                    <tr>
                        <th>Indicado Por</th>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Cidade</th>
                        <th>CPF/CNPJ</th>
                        <th>Termos de uso</th>
                        <th>Status</th>
                        <th>Cadastro</th>
                    </tr>
                </thead>
                <tbody className="tr-salespeople">
                    {sellers.map((item) => (
                        <tr key={item.id} className="tr-salespeople-background">
                            <td>{item.referrer_name || 'Não Informado'}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.city || ''}</td>
                            <td>{item.document_number}</td>
                            <td>{item.adherence_agreement ? 'Aceito' : 'Pendente'}</td>
                            <td>{translateSellerStatus(item.status)}</td>
                            <td>{format(parseISO(item.created_at), 'dd/MM/yyyy')}</td>
                            <td className="icons">
                                <img src={view} alt="view" onClick={() => setSeller(item)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal
                seller={seller}
                onModalClose={() => setSeller(undefined)}
                onNewsStatusConfirmed={handleStatusUpdate}
            />
        </SalespeopleContainer>
    )
}

export default Salespeople;
