import React, { useCallback, useEffect, useState, useRef } from 'react';
import * as Yup from 'yup'
import getValidationError from '../../services/utils/getValidationErrors'

import { ICourseBody, emptyCourse, CourseCategoryEnum } from '../../models/Course';

import close from '../../Assets/icons/close.svg';

import { ModalContainer } from './styles'

import { IValidPaymentStatusFilter, PaymentStatusEnum } from '../../models/Payment';

import { useToast } from '../../hooks/toast';

interface IModal {
  isOpenModal: boolean;
  onRequestClose: () => void;
  handleConfirmation: (newStatus: IValidPaymentStatusFilter) => Promise<boolean>
  filteredStatus: IValidPaymentStatusFilter | undefined
}

const Modal: React.FC<IModal> = ({
  isOpenModal,
  onRequestClose,
  handleConfirmation,
  filteredStatus
}) => {
  const { addToast } = useToast();
  
  const [selectedStatus, setSelectedStatus] = useState<IValidPaymentStatusFilter | undefined>();

  useEffect(() => {
    setSelectedStatus(undefined);
  }, [isOpenModal])

  function handleCloseModal() {
    onRequestClose()
  }

  const handleStatusConfirmation = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (!selectedStatus) {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Nenhum status foi selecionado',        
      });
      return;
    }

    const res = handleConfirmation(selectedStatus);

    if (res) {
      onRequestClose()
    }
  };

  return (
    <ModalContainer isOpenModal={isOpenModal} className="animeTop">
      <button id="close" onClick={handleCloseModal}><img src={close} alt="fechar modal" /></button>
      <form className="modal-container" onSubmit={handleStatusConfirmation}>
        {/* <button
          type="button"
          hidden={filteredStatus === PaymentStatusEnum.waiting}
          className={selectedStatus === PaymentStatusEnum.waiting ? 'pay-status selected' : 'pay-status'}
          onClick={() => setSelectedStatus(PaymentStatusEnum.waiting)}
        >Pendente</button> */}

        <button
          type="button"
          hidden={filteredStatus === PaymentStatusEnum.paid}
          className={selectedStatus === PaymentStatusEnum.paid ? 'pay-status selected' : 'pay-status'}
          onClick={() => setSelectedStatus(PaymentStatusEnum.paid)}
        >Pago</button>

        <button
          type="button"
          hidden={filteredStatus !== PaymentStatusEnum.paid}
          className={selectedStatus === PaymentStatusEnum.no_paid ? 'pay-status selected' : 'pay-status'}
          onClick={() => setSelectedStatus(PaymentStatusEnum.no_paid)}
        >Não Pago</button>

        <button type="submit" className="confirm">Confirmar</button>
      </form>
    </ModalContainer>
  )
}

export default Modal
