import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from './styles/globalStyles';

import Routes from './routes';
import { UserProvider } from './UserContext'
import AppProvider from './hooks';

const App = () => (
  <BrowserRouter>
    <GlobalStyles />
    <AppProvider>
      <UserProvider>
        <Routes />
      </UserProvider>
    </AppProvider>
  </BrowserRouter >
)

export default App;
