import styled from 'styled-components'

export const ContainerSidebar = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;

    min-width: 28rem;
    @media (min-width: 1600px) {
        min-width: 32rem;
    }
    @media (max-width: 911px) {
        display: none;
    }
    .user-profile {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-bottom: 4rem;
        text-align: center;
        img {
            width: 12rem;
        }
        h3 {
            font-size: 1.8rem;
            line-height: 1.9rem;
            font-weight: 800;
            text-align: center;
            word-break: break-word;
            
            max-width: 25rem;
            margin-top: 12px;
        }
        h2 {
            color: #FFF500;
            font-size: 2.3rem;
            font-weight: normal;
            line-height: 2.3rem;
            margin-top: 1.6rem;
        }
        svg {
            width: 2rem;
            height: 2rem;
            margin: 1rem auto 0;
            
            &:hover {
                cursor: pointer;
            }
        }
    }
    .container-links {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: 12rem;
        min-height: 26rem;
        @media(min-width: 1600px) {
            min-height: 30rem;
        }
        @media(min-width: 1800px) {
            min-height: 32rem;
        }
        a {
            display: flex;
            align-items: center;
            font-weight: normal;
            text-decoration: none;
            span {
                color: #fff;
                font-size: 1.2rem;
                line-height: 1.2rem;

                margin-left: .8rem;
                padding: .4rem;
                &:hover {
                    font-weight: 700;
                }
                @media(min-width: 1600px) {
                    font-size: 1.4rem;
                    line-height: 1.4rem;
                }
                @media(min-width: 1800px) {
                    font-size: 1.6rem;
                    line-height: 1.6rem;
                }
            }
        }
        .active  {
            font-weight: 700;
        }
    }
`
