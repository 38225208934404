import { AxiosResponse } from 'axios';
import useApiCall from '../../../hooks/apiCall';
import { ICourse, ICourseBody } from '../../../models/Course';
import api, { RawHTTPResponse, PaginatedResponse, getAuthHeader } from '../api';

interface CoursesList {
  Request: {
    cursor?: string;
    nameFilter?: string;
  };
  Response: PaginatedResponse<ICourse>;
}

export const useCoursesList = () => useApiCall<CoursesList['Request'], CoursesList['Response']>({
  request: async ({ cursor = '', nameFilter = '' }, token) => {
    const { data }: RawHTTPResponse<CoursesList['Response']> = await api.get(
      '/courses?'
        + (cursor?.length > 0 ? `after_cursor=${cursor}&` : '')
        + (nameFilter?.length > 0 ? `filter_name=${nameFilter}` : ''),
      getAuthHeader(token)
    );
    return data;
  },
  errorMessage: {
    title: 'Erro na requisição',
    description: 'Não foi possível carregar os cursos cadastrados',
  },
});

interface AddNewCourse {
  Request: {
    course: ICourseBody;
    imageFile: File;
  };
  Response: ICourse
};

export const useAddNewCourse = () => useApiCall<AddNewCourse['Request'], AddNewCourse['Response']>({
  request: async ({ course, imageFile }, token) => {
    const formData = new FormData();
    formData.append('name', course.name);
    formData.append('description', course.description);
    formData.append('price', course.price.toString());
    formData.append('promotional_price', course.promotional_price.toString());
    formData.append('category', course.category);
    formData.append('picture', imageFile);

    const { data }: RawHTTPResponse<AddNewCourse['Response']> = await api.post(
      '/courses',
      formData,
      getAuthHeader(token)
    );
    return data;
  },
  errorMessage: {
    title: 'Erro na requisição',
    description: 'Não foi possível cadastrar o curso',
  },
});

interface EditCourse {
  Request: {
    course: ICourseBody;
    imageFile: File;
  };
  Response: ICourse
};

export const useEditCourse = () => useApiCall<EditCourse['Request'], EditCourse['Response']>({
  request: async ({ course, imageFile }, token) => {
    const formData = new FormData();
    formData.append('id', course.id);
    formData.append('name', course.name);
    formData.append('description', course.description);
    formData.append('price', course.price.toString());
    formData.append('promotional_price', course.promotional_price.toString());
    formData.append('category', course.category);

    if (imageFile.name.length > 0) {
      formData.append('picture', imageFile);
    }

    const { data }: RawHTTPResponse<EditCourse['Response']> = await api.put(
      '/courses',
      formData,
      getAuthHeader(token)
    );
    return data;
  },
  errorMessage: {
    title: 'Erro na requisição',
    description: 'Não foi possível atualizar o curso',
  },
});

interface DeleteCourse {
  Request: {
    id:string;
    
  };
  Response: void;
}

export const useDeleteCourse = () => useApiCall<DeleteCourse['Request'], AxiosResponse<DeleteCourse['Response']>>({
  request: async ({
    id,
    
      }, token) => {
      const res : RawHTTPResponse<DeleteCourse['Response']> = await api.delete(
      `/courses?id=${id}`, getAuthHeader(token));
      return res;
  }, errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível deletar a pergunta',
    },

});