import React, { useContext, useEffect, useState } from 'react';

import Sidebar from '../../components/Sidebar';
import MenuResponsive from '../../components/MenuResponsive/index';
import Input from '../../components/Input'
import SkeletonLoader from '../../components/SkeletonLoader';

import Modal from './Modal'
import { ICourseBody, emptyCourse } from '../../models/Course'
import { formatCurrency } from '../../services/utils/format'

import logo from '../../Assets/logo.png';
import capelo from '../../Assets/icons/capelo-grande.svg';

import { Background, Container, Main } from '../../styles/GenericStyles';
import { CourseContainer, Courses } from './styles';

import useDataPaginator from '../../hooks/dataPaginator';

import { UserContext } from '../../UserContext';
import { useCoursesList, useAddNewCourse, useEditCourse } from '../../services/api';
import useDebouncedText from '../../hooks/debouncedText';

const Course: React.FC = () => {
    const { isBackgroundFixed } = useContext(UserContext);

    const getCoursesList = useCoursesList();
    const addNewCourse = useAddNewCourse();
    const editCourse = useEditCourse();

    const [nameFilter, setNameFilter] = useDebouncedText('');
    const [thisCourse, setThisCourse] = useState<ICourseBody | undefined>(undefined)

    const [
        coursesList,
        setCoursesList,
        isCoursesListLoading,
        checkScrollBottom
    ] = useDataPaginator<ICourseBody, [string]>({
        filters: [nameFilter],
        storeNewData: async (filters, cursor) => {
            const res = await getCoursesList({
                cursor: cursor as string,
                nameFilter: filters[0]
            });
            //console.log('---<<>>', res);
            return {
                nextCursor: res.cursor.afterCursor,
                shouldResetData: !res.cursor.beforeCursor,
                nextData: res.data
            }
        }
    });




    async function handleCourse(course: ICourseBody, imageFile: File) {

        if (course.id.length > 0) {
            try {
                const res = await editCourse({ course, imageFile });

                setCoursesList((currentCourseList) => currentCourseList.map(currentCourse => {
                    if (currentCourse.id === course.id) {
                        return course
                    }
                    return currentCourse
                }));

                return true;
            } catch {
                return false;
            };
        } else {
            try {
                const newCourse = await addNewCourse({ course, imageFile });

                setCoursesList((currentCourseList) => ([newCourse, ...currentCourseList]));

                return true;
            } catch {
                return false;
            };
        }
    }

    return (
        <Background isBackgroundFixed={isBackgroundFixed}>
            <Container>
                <MenuResponsive />
                <Sidebar />
                <Main>
                    <img src={logo} width="152" height="76" alt="FAEL" />
                    <section>
                        <CourseContainer>
                            <div className="course-image">
                                <img src={capelo} alt="capelo" />
                                <h1>Cursos</h1>
                            </div>
                            <div className="search-and-add">
                                <Input
                                    type="text"
                                    placeholder="Curso"
                                    onChange={ (e) => setNameFilter(e.target.value)}
                                />
                                <button type="button" onClick={() => setThisCourse(emptyCourse)}>Adicionar Curso</button>
                            </div>
                            <ul onScroll={checkScrollBottom}>
                                {coursesList.map((item) =>
                                    <Courses
                                        key={item.id}
                                        photoCourse={item.picture}
                                        onClick={() => setThisCourse(item)}
                                    >
                                        <h2>{item.name}</h2>
                                        <div className="about-course">
                                            <div>
                                            <p >{item.description.length > 98 ? 
                                            item.description.replaceAll('*%*', ' ').slice(0, 95) + '...' 
                                            : item.description.replaceAll('*%*', ' ') }</p>
                                            </div>
                                            <div className="prices">
                                                <span className="full-price">{formatCurrency(item.price)}</span>
                                                <span className="discount-price">{formatCurrency(item.promotional_price)}</span>
                                            </div>
                                        </div>
                                    </Courses>
                                )}

                                {/* {isCoursesListLoading && <Loader />} */}

                                {coursesList.length === 0
                                    && !isCoursesListLoading
                                    && <h2>
                                        Nenhum curso foi encontrado
                                    </h2>
                                }
                            </ul>
                            <Modal
                                isOpenModal={!!thisCourse}
                                onRequestClose={() => setThisCourse(undefined)}
                                thisCourse={thisCourse}
                                handleCourse={handleCourse}
                            />
                        </CourseContainer>
                    </section>
                </Main>
            </Container>
        </Background>
    );
};


export default Course;
