import React, { ChangeEvent, InputHTMLAttributes } from 'react';

import { InputContainer } from './styles';

interface IInputProps {
    types: string;
    placeholder: string;
    onChange?: (value: string) => void
};
const Input: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({
    type,
    placeholder,
    name,
    value,
    onChange = () => ({}),
}) => {
    return (
        <InputContainer
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
        />
    );
};

export default Input;
