export interface ITrainingVideo {
    id: string;
    title: string;
    description: string;
    category: string;
    link_url: string;
    set_home_page_date: string;
    is_available: boolean;
    is_active: boolean;
    picture: string;
    created_at: string;
    updated_at: string;
    
};

export type ITrainingVideoBody = Omit<ITrainingVideo, 'is_available' | 'is_active' | 'created_at' | 'updated_at'>

export const emptyVideo: ITrainingVideoBody = {
    id: '',
    title: '',
    description: '',
    picture: '',
    link_url: '',
    category: '',
    set_home_page_date: new Date().toDateString()
};