import api, { RawHTTPResponse, getAuthHeader, PaginatedResponse } from '../api';
import useApiCall from '../../../hooks/apiCall';
import { ITrainingVideoBody } from '../../../models/TrainingVideo';
import { AxiosResponse } from 'axios';

interface GetTrainingVideosList {
    Request: {
      cursor?: string;
      nameFilter?: string;
    };
    Response: PaginatedResponse<ITrainingVideoBody>;
}
  
export const useGetTrainingVideoList = () => useApiCall<GetTrainingVideosList['Request'], GetTrainingVideosList['Response']>({
    request: async ({ cursor = '', nameFilter = '' }, token) => {
        const { data }: RawHTTPResponse<GetTrainingVideosList['Response']> = await api.get(
        '/video?'
            + (cursor?.length > 0 ? `after_cursor=${cursor}&` : '')
            + (nameFilter?.length > 0 ? `filter_name=${nameFilter}` : ''),
        getAuthHeader(token)
        );
        return data;
    },
    errorMessage: {
        title: 'Erro na requisição',
        description: 'Não foi possível carregar os videos cadastrados',
    },
});
  
interface AddNewTrainingVideo {
    Request: {
        trainingVideo: ITrainingVideoBody;
        imageFile: File;
    };
    Response: ITrainingVideoBody
};
  
  export const useAddNewVideo = () => useApiCall<AddNewTrainingVideo['Request'], AddNewTrainingVideo['Response']>({
    request: async ({ trainingVideo, imageFile }, token) => {
      const formData = new FormData();
      formData.append('title', trainingVideo.title);
      formData.append('description', trainingVideo.description);
      formData.append('link_url', trainingVideo.link_url);
      formData.append('category', trainingVideo.category);
      formData.append('set_home_page_date', trainingVideo.set_home_page_date);
      formData.append('picture', imageFile);
  
      const { data }: RawHTTPResponse<AddNewTrainingVideo['Response']> = await api.post(
        '/video',
        formData,
        getAuthHeader(token)
      );
      return data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível cadastrar o video',
    },
  });
  
  interface EditTrainingVideo {
    Request: {
      trainingVideo: ITrainingVideoBody;
      imageFile: File;
    };
    Response: ITrainingVideoBody
  };
  
export const useEditTrainingVideo = () => useApiCall<EditTrainingVideo['Request'], EditTrainingVideo['Response']>({
    request: async ({ trainingVideo, imageFile }, token) => {
        const formData = new FormData();
        formData.append('id', trainingVideo.id);
        formData.append('title', trainingVideo.title);
        formData.append('description', trainingVideo.description);
        formData.append('link_url', trainingVideo.link_url);  
        formData.append('category', trainingVideo.category);
        formData.append('set_home_page_date', trainingVideo.set_home_page_date);
        if (imageFile.name.length > 0) {
            formData.append('picture', imageFile);
        }
    
        const { data }: RawHTTPResponse<EditTrainingVideo['Response']> = await api.put(
            '/video',
            formData,
            getAuthHeader(token)
        );

        return data;
    },
    errorMessage: {
        title: 'Erro na requisição',
        description: 'Não foi possível atualizar o treino',
    },
});
  
interface DeleteVideo {
  Request: {
    id:string;
    
  };
  Response: void;
}

export const useDeleteVideo = () => useApiCall<DeleteVideo['Request'], AxiosResponse<DeleteVideo['Response']>>({
  request: async ({
    id,
    
      }, token) => {
      const res : RawHTTPResponse<DeleteVideo['Response']> = await api.delete(
      `/video?id=${id}`, getAuthHeader(token));
      return res;
  }, errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível deletar o vídeo',
    },

});
