import styled from 'styled-components';

export const MenuContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 10;

  @media (min-width: 912px) {
    display: none;
  }
`;
