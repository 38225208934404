import React, { useEffect, useState } from 'react';

import Input from '../../components/Input';

import Modal from './Modal';
import { ITrainingVideoBody, emptyVideo } from '../../models/TrainingVideo';

import capelo from '../../Assets/icons/capelo-grande.svg';
import { VideosContainer, VideoBox } from './styles';

import useDataPaginator from '../../hooks/dataPaginator';

import { useGetTrainingVideoList, useAddNewVideo, useEditTrainingVideo } from '../../services/api/videos';
import useDebouncedText from '../../hooks/debouncedText';
 
const Videos: React.FC = () => {
  const getVideosList = useGetTrainingVideoList();
  const addNewVideo = useAddNewVideo();
  const editVideo = useEditTrainingVideo();

  const [nameFilter, setNameFilter] = useDebouncedText('');
  const [thisVideo, setThisVideo] = useState<ITrainingVideoBody | undefined>(undefined)

  const [
    videosList,
    setVideosList,
    isVideosListLoading,
    checkDataListBottom,
  ] = useDataPaginator<ITrainingVideoBody, [string]>({
    filters: [nameFilter],
    storeNewData: async (filters, cursor) => {
      const res = await getVideosList({
        cursor: cursor as string,
        nameFilter: filters[0]
      });
      //console.log('---<<>>', res);
      return {
        nextCursor: res.cursor.afterCursor,
        shouldResetData: !res.cursor.beforeCursor,
        nextData: res.data
      }
    }
  });


  useEffect(() => {
    console.log({ videosList })
  }, [videosList])

  useEffect(() => {
    console.log({ isVideosListLoading });
  }, [isVideosListLoading]);

  async function handleVideo(trainingVideo: ITrainingVideoBody, imageFile: File) {

    console.log('handleVideo', { trainingVideo }, { imageFile })

    if (trainingVideo.id.length > 0) {
      try {
        const res = await editVideo({ trainingVideo, imageFile });

        setVideosList((currentVideoList) => currentVideoList.map(currentVideo => {
          if (currentVideo.id === trainingVideo.id) {
            return trainingVideo
          }
          return currentVideo
        }));

        return true;
      } catch {
        return false;
      };
    } else {
      try {
        const newVideo = await addNewVideo({ trainingVideo, imageFile });

        setVideosList((currentVideoList) => ([newVideo, ...currentVideoList]));

        return true;
      } catch {
        return false;
      };
    }
  }

  return (
    <VideosContainer>
      <div className="intro-container">
        <img src={capelo} alt="capelo" />
        <h1>Treinamentos</h1>
      </div>
      <div className="search-and-add">
        <Input
          type="text"
          placeholder="Video"
          onChange={(e) => setNameFilter(e.target.value)}
        />
        <button type="button" onClick={() => setThisVideo(emptyVideo)}>Adicionar Video</button>
      </div>
      <ul onScroll={checkDataListBottom}>
      {videosList.length ? videosList.map((item) => (
        <VideoBox
        key={item.id} 
        photoCourse={item.picture}
        onClick={() => setThisVideo(item)}
    >
        <h2>{item.title}</h2>
        <div className="about-course">
            <p>{item.description}</p>
        </div>
    </VideoBox>
      )) :            <span>Não encontramos nenhum vídeo</span>
    }
    </ul>
      <Modal
        isOpenModal={!!thisVideo}
        onRequestClose={() => setThisVideo(undefined)}
        thisVideo={thisVideo}
        handleVideo={handleVideo}
      />
    </VideosContainer >
  );
};

export default Videos;
