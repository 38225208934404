import React, { useState, useRef } from 'react';
import { useOnClickOutside } from './hooks';
import Burger from './Burger/Burger';
import Menu from './Menu/Menu';

import { MenuContainer } from './styles';

const MenuResponsive: React.FC = () => {
  const [open, setOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const openMenu = React.useCallback(
    (option) => {
      document.body.className = option ? 'menu-open' : '';
      setOpen(option);
    },
    [setOpen]
  );

  useOnClickOutside(menuRef, () => {
    document.body.className = '';
    setOpen(false);
  });

  return (
    <MenuContainer ref={menuRef}>
      <Burger open={open} setOpen={openMenu} />
      <Menu open={open} setOpen={openMenu} />
    </MenuContainer>
  );
}

export default MenuResponsive;
