import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";

import { ISale } from "../../models/Sale";
import { useAddStudentLogin } from "../../services/api/clients";

import { Table } from "../../styles/GenericStyles";
import { getCurrencyMask } from "../../utils/currencyFormatter";

const Schedules: React.FC<{
  data: ISale[];
  onScroll: (e: React.UIEvent<HTMLElement>) => void;
}> = ({ data, onScroll }) => {
  const addStudentLogin = useAddStudentLogin();
  const studentInfo = data.map(({ student }) => {
    return {
      id: student.id,
      login: student.login || "",
    };
  });

  const [loginContent, setLoginContent] = useState<
    {
      id: string;
      login: string | null;
    }[]
  >([]);
  const [inputValue, setInputValue] = useState<
    {
      id: string;
      login: string | null;
    }[]
  >([]);

  const handleInputChange = (
    student_id: string,
    student_login: string,
    i: number
  ) => {
    setInputValue((students) =>
      students.map((s) => {
        if (s.id === loginContent[i].id) {
          return {
            ...s,
            login: student_login,
          };
        } else {
          return s;
        }
      })
    );
  };

  const handleLoginUpdate = async (
    student_id: string,
    student_login: string,
    i: number
  ) => {
    setLoginContent((students) =>
      students.map((s) => {
        if (s.id === loginContent[i].id) {
          return {
            ...s,
            login: student_login,
          };
        } else {
          return s;
        }
      })
    );
    try {
      await addStudentLogin({ student_id, student_login });
    } catch (error) {}
  };

  console.log("Fora", { data, loginContent });

  useEffect(() => {
    setLoginContent(studentInfo);
    setInputValue(studentInfo);
  }, [data]);

  return (
    <>
      <thead>
        <tr>
          <th>Aluno</th>
          <th>Login</th>
          <th>Curso</th>
          <th>Vendedor</th>
          <th>Valor</th>
          <th>Adesão</th>
        </tr>
      </thead>
      <Table onScroll={onScroll}>
        <tbody>
          {loginContent.map((student, i) => {
            if (data[i]) {
              return (
                <tr key={student.id}>
                  <td>{data[i].student.name}</td>
                  {loginContent[i].login ? (
                    <td>{loginContent[i].login}</td>
                  ) : (
                    <>
                      <input
                        className="login-input"
                        placeholder="Login"
                        onChange={(e) => {
                          handleInputChange(
                            loginContent[i].id,
                            e.target.value,
                            i
                          );
                        }}
                      />

                      {inputValue[i].login ? (
                        <button
                          type="button"
                          id="ok"
                          onClick={() =>
                            handleLoginUpdate(
                              loginContent[i].id,
                              inputValue[i].login as string,
                              i
                            )
                          }
                        />
                      ) : (
                        <button type="button" id="ok" disabled={true} />
                      )}
                    </>
                  )}
                  <td>{data[i].course.name}</td>
                  <td>{data[i].user.name}</td>
                  <td>{getCurrencyMask(data[i].value_of_course)}</td>
                  <td>{format(parseISO(data[i].created_at), "dd/MM/yyyy")}</td>
                </tr>
              );
            }
          })}
        </tbody>
      </Table>
    </>
  );
};

export default Schedules;
