import React from 'react';
import { bool, func } from 'prop-types';

import { StyledBurger } from './styles';

import { UserContext } from '../../../UserContext'

const Burger = ({ open, setOpen }) => {
  const { isBackgroundFixed, setIsBackgroundFixed } = React.useContext(UserContext);

  function handleClickMenu() {
    setOpen(!open)
    setIsBackgroundFixed(!isBackgroundFixed)
  }

  return (
    <StyledBurger
      open={open}
      onClick={handleClickMenu}
      aria-label="Menu"
      title="Menu"
    >
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Burger;
