import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';

import fotoPerfil from '../../Assets/foto-modal.png';
import home from '../../Assets/icons/Home.svg';
import dinheiro from '../../Assets/icons/dinheiro.svg';
import capelo from '../../Assets/icons/capelo.svg';
import cumprimento from '../../Assets/icons/cumprimento.svg';
import compliance from '../../Assets/icons/compliance.svg';
import duvidas from '../../Assets/icons/duvidas.svg';
import vendas from '../../Assets/icons/vendas.svg';


import { ContainerSidebar } from './styles';
import { useAuth } from '../../hooks/auth';

const Sidebar: React.FC = () => {
  const { user, signOut } = useAuth();
  const { push } = useHistory()


  return (
    <ContainerSidebar>
      <div className="user-profile">
        <img src={fotoPerfil} alt="Foto de perfil" />
        <h3>Olá,
          {` ${user?.name || 'Usuário Desconhecido'}`}
        </h3>
        <h2>Admin</h2>
        <FiLogOut
          onClick={()=> {
          signOut();
          push('/')
          }}
        />
      </div>
      <div className="container-links">
        <NavLink to="/home" activeClassName="active">
          <img src={home} alt="Home" />
          <span>Home</span>
        </NavLink>
        <NavLink to="/financial" activeClassName="active">
          <img src={dinheiro} alt="Financeiro" />
          <span>Financeiro</span>
        </NavLink>
        <NavLink to="/course" activeClassName="active">
          <img src={capelo} alt="Cursos" />
          <span>Cursos</span>
        </NavLink>
        <NavLink to="/client" activeClassName="active">
          <img src={cumprimento} alt="Clientes" style={{ maxWidth: "30px" }} />
          <span style={{ marginLeft: "2px" }}>Clientes</span>
        </NavLink>
        <NavLink to="/compliance" activeClassName="active">
          <img src={compliance} alt="Aprovação" style={{ maxWidth: "30px" }} />
          <span style={{ marginLeft: "2px" }}>Aprovação</span>
        </NavLink>
        <NavLink to="/faq" activeClassName="active">
          <img src={duvidas} alt="Central de Ajuda" style={{ maxWidth: "30px" }} />
          <span>FAQ</span>
        </NavLink>
        <NavLink to="/training" activeClassName="active">
          <img src={vendas} alt="Treinamento" style={{ maxWidth: "30px" }} />
          <span>Treinamento</span>
        </NavLink>
      </div>
    </ContainerSidebar>
  );
};

export default Sidebar;
