import React, { useContext } from 'react'

import Sidebar from '../../components/Sidebar';
import MenuResponsive from '../../components/MenuResponsive/index';

import logo from '../../Assets/logo.png';

import FaqComponent from './FaqComponent'
import { Background, Container, Main } from '../../styles/GenericStyles';

import { UserContext } from '../../UserContext';

const Faq: React.FC = () => {
  const { isBackgroundFixed } = useContext(UserContext);

  return (
    <Background isBackgroundFixed={isBackgroundFixed}>
      <Container>
        <MenuResponsive />
        <Sidebar />
        <Main>
          <img src={logo} width="152" height="76" alt="FAEL" />
          <section>
            <FaqComponent />
          </section>
        </Main>
      </Container>
    </Background>
  )
}

export default Faq;
