import { ISale } from './Sale';

export enum PaymentStatusEnum {
    new = 'New',
    paid = 'Paid',
    waiting = 'Waiting',
    no_paid = 'No Paid',
}

export const translatePaymentStatus = (status: PaymentStatusEnum) => {
    switch (status) {
        case PaymentStatusEnum.new: return 'Novo';
        case PaymentStatusEnum.waiting: return 'Pendente';
        case PaymentStatusEnum.paid: return 'Pago';
        case PaymentStatusEnum.no_paid: return 'Não Pago';
        default: return 'Pendente';
    }
};

export type IValidPaymentStatusFilter = Exclude<PaymentStatusEnum, PaymentStatusEnum.new>;

enum PaymentTypeEnum {
    credit = 'Crédito',
    bank_vouch = 'Boleto',
}

export interface IPayment {
    id: string;
    payment_status: PaymentStatusEnum;
    paid_date: string;
    payment_type: PaymentTypeEnum;
    value: number;
    payment_reference: string;
    card_mask: string;
    sales: ISale[];
    is_available: boolean;
    is_active: boolean;
    created_at: string;
    updated_at: string;
}