import React, { useEffect, useState } from 'react';

import close from '../../Assets/icons/close.svg';
import { CourseCategoryEnum } from '../../models/Course';
import { emptyFaq, FaqCategoryEnum } from '../../models/Faq';
import { useAddFAQ, useDeleteFAQ, useEditFAQ } from '../../services/api/faq';

import { ModalContainer } from './styles'

interface IModal {
  isOpenModal: boolean;
  idFAQ?: string;
  descriptionFAQ?: string
  questionFAQ?: string
  categoryFAQ?: FaqCategoryEnum
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}

const Modal: React.FC<IModal> = ({
  isOpenModal,
  setIsOpenModal,
  idFAQ,
  descriptionFAQ,
  questionFAQ,
  categoryFAQ
}) => {
  

  const addFAQ = useAddFAQ()
  const editFAQ = useEditFAQ()
  const deleteFAQ = useDeleteFAQ()

  const [category, setCategory] = useState<FaqCategoryEnum>(( categoryFAQ as unknown)as FaqCategoryEnum)
  const [question, setQuestion] = useState<string>(questionFAQ || '')
  const [description, setDescription] = useState<string>(descriptionFAQ || '')

  useEffect(()=>{
    setCategory(categoryFAQ || FaqCategoryEnum.receiving_and_commissions)
    setQuestion(questionFAQ || '')
    setDescription(descriptionFAQ || '')
  }, [idFAQ])
  



  const handleConfirmation = async () => {

    if (idFAQ && category) {
    try {
      const courseSaved = await editFAQ({question, category, description, id: idFAQ}); //fazer verificação
      if (courseSaved ) {
        setIsOpenModal(false)
      }

    } catch (error) {
      console.log(error)
    }

    } else if (category){
      try {
        const courseSaved = await addFAQ({question, category, description }); //fazer verificação
        if (courseSaved ) {
          setIsOpenModal(false)

        }
  
      } catch (error) {
        console.log(error)
     
      }
    }
  }
  const handleDeletion = async () => {
    if (idFAQ) {
      try {
        const courseSaved = await deleteFAQ({ id: idFAQ }); //fazer verificação
        if (courseSaved ) {
          setIsOpenModal(false)
        }
  
      } catch (error) {
        console.log(error)
     
      }
    } 
   
  }

  
  return (
    <ModalContainer isOpenModal={isOpenModal} className="animeTop">
      <button id="close" onClick={() => setIsOpenModal(false)}><img src={close} alt="fechar modal" /></button>
      <form className="modal-container">
        <div className="fieldset-container">
          <fieldset className="grid-left">
            <div className="input-data">
              <label>Pergunta</label>
              <input type="text"
              value={question}
              onChange={(e)=> setQuestion(e.target.value)}
              />
            </div>
          </fieldset>
          <fieldset className="grid-right">
            <div className="input-data">
              <label>Categoria</label>
              {/* {course && <select
                defaultValue={course.category}
                onChange={(e) => {
                  const category = e.target.value as CourseCategoryEnum;
                  setCourse((c) => ({ ...c, category }))
                }}
              >
                {Object.values(CourseCategoryEnum).map((cat) => (
                  <option key={cat} value={cat}>
                    {cat}
                  </option>)
                )}
              </select>} */}
              <select value={
                    [...Object.values(FaqCategoryEnum)].includes(
                      category,
                    )
                      ? category
                      : 'NULL'
                  }
                  onChange={(e)=> setCategory((e.target.value as unknown) as FaqCategoryEnum)}>
                    {console.log('category', category)}
               <option value="NULL" disabled>
                    Selecione
                  </option>
                  {Object.values(FaqCategoryEnum).map(v => (
                    <option key={v} value={v}>
                      {v}
                    </option>
                  ))} 
              </select>
            </div>
          </fieldset>
        </div>
        <div className="input-data">
          <label htmlFor="description">Descrição</label>
          <textarea
            id="description"
            name="description"
            placeholder="Resposta..."
            value={description}
            onChange={(e)=> setDescription(e.target.value)}
          />
        </div>
        {idFAQ &&
        <button type="button" onClick={() => handleDeletion()} className="delete">Deletar</button>}
        <button type="button" onClick={() => handleConfirmation()} className="confirm">{idFAQ ? 'Atualizar' : 'Confirmar'}</button>
      </form>
    </ModalContainer>
  )
}

export default Modal
