import api, { RawHTTPResponse, getAuthHeader, PaginatedResponse } from '../api';
import useApiCall from '../../../hooks/apiCall';
import { IFaqBody } from '../../../models/Faq';
import { AxiosResponse } from 'axios';

interface GetFAQ {
  Request: {
    filter_name?: string;
    };
    Response: PaginatedResponse<IFaqBody>;
}
  
export const useGetFAQ = () => useApiCall<GetFAQ['Request'], GetFAQ['Response']>({
  request: async ({
      filter_name,
      }, token) => {
      const { data }: RawHTTPResponse<GetFAQ['Response']> = await api.get(
          ''.concat(
              `/FAQ?`,
              filter_name ? `&filter_name=${filter_name}` : '',
          ),
        getAuthHeader(token)
      );
      return data;
  },
  errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível encontrar nenhuma pergunta',
  },
});

interface AddFAQ {
  Request: {
      question: string;
      description: string;
      category: string
  };
  Response: void;
}

export const useAddFAQ = () => useApiCall<AddFAQ['Request'], AxiosResponse<AddFAQ['Response']>>({
  request: async ({
      question,
      description,
      category
      }, token) => {
      const res : RawHTTPResponse<AddFAQ['Response']> = await api.post(
      '/FAQ', {
          question,
          description,
          category
      }, getAuthHeader(token));
      return res;
  }, errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível adicionar a pergunta',
    },

});

interface EditFAQ {
  Request: {
    id:string;
      question: string;
      description: string;
      category: string
  };
  Response: void;
}

export const useEditFAQ = () => useApiCall<EditFAQ['Request'], AxiosResponse<EditFAQ['Response']>>({
  request: async ({
    id,
      question,
      description,
      category
      }, token) => {
      const res : RawHTTPResponse<EditFAQ['Response']> = await api.put(
      '/FAQ', {
        id,
          question,
          description,
          category
      }, getAuthHeader(token));
      return res;
  }, errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível atualizar a pergunta',
    },

});
interface DeleteFAQ {
  Request: {
    id:string;
    
  };
  Response: void;
}

export const useDeleteFAQ = () => useApiCall<DeleteFAQ['Request'], AxiosResponse<DeleteFAQ['Response']>>({
  request: async ({
    id,
    
      }, token) => {
      const res : RawHTTPResponse<DeleteFAQ['Response']> = await api.delete(
      `/FAQ?id=${id}`, getAuthHeader(token));
      return res;
  }, errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível deletar a pergunta',
    },

});