import React, {
  createContext, useCallback, useContext, useState, useRef, useEffect,
} from 'react';
import LoadingBar from 'react-top-loading-bar';
import { colors } from '../styles/globalStyles';

interface LoadingBarContextData {
  isBarLoading: boolean,
  startBarLoading: () => void,
  completeBarLoading: () => void
}

const LoadingBarContext = createContext<LoadingBarContextData>({} as LoadingBarContextData);

interface LoadingBarRef {
  add(value: number): void;
  decrease(value: number): void;
  continuousStart(startingValue?: number, refreshRate?: number): void;
  staticStart(startingValue: number): void;
  complete(): void;
}

const LoadingBarProvider: React.FC = ({ children }) => {
  const [isBarLoading, setIsBarLoading] = useState<boolean>(false);

  const loadingBarRef = useRef<LoadingBarRef>(null);

  const startBarLoading = () => {
    setIsBarLoading(true);
  };

  const completeBarLoading = () => {
    setIsBarLoading(false);
  };

  useEffect(() => {
    if (isBarLoading) {
        loadingBarRef.current!.continuousStart();
    } else {
        loadingBarRef.current!.complete();
    }
  }, [isBarLoading]);

  return (
    <LoadingBarContext.Provider value={{ isBarLoading, startBarLoading, completeBarLoading }}>
      <LoadingBar color={colors.primary} ref={loadingBarRef} shadow={false} />
      {children}
    </LoadingBarContext.Provider>
  );
};

function useLoadingBar(): LoadingBarContextData {
  const context = useContext(LoadingBarContext);

  if (!context) {
    throw new Error('useLoadingBar must be used within a LoadingBarProvider');
  }

  return context;
}

export { LoadingBarProvider, useLoadingBar };
