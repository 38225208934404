import React, {
  createContext, useCallback, useState, useContext,
} from 'react';
import { useLogin } from '../services/api';
import { ILocalStorageUser } from '../models/User';
import { useToast } from './toast';

interface AuthState {
  token: string;
  user: ILocalStorageUser;
}

interface SignInCredentials {
  email: string;
  password: string;
}

// eslint-disable-next-line no-shadow
enum LocalStorageItems {
  User = '@faelManager:user',
  Token = '@faelManager:token'
}

interface AuthContextData {
  user: ILocalStorageUser;
  token: string;
  signIn(credentials: SignInCredentials): Promise<void>;
  updateLocalStorageUser(user: ILocalStorageUser): void;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData,
);

const AuthProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();

  const login = useLogin();

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem(LocalStorageItems.Token);
    const user = localStorage.getItem(LocalStorageItems.User);

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }
    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await login({
      email,
      password,
    });

    try {
      if (!response) {
        throw new Error('Falha no login');
      }

      const { token, user } = response;
      localStorage.setItem(LocalStorageItems.Token, token);
      localStorage.setItem(LocalStorageItems.User, JSON.stringify({
        email: user.email,
        name: user.name,
        avatar: user.avatar,
        profile_class: user.profile_class,
        profile_role: user.profile_role,
      }));

      setData({ token, user });
    } catch (error) {
      console.log({ type: 'SignIn error', error });

      addToast({
        type: 'error',
        title: '',
        description: 'Erro ao salvar sessão',
      });
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem(LocalStorageItems.Token);
    localStorage.removeItem(LocalStorageItems.User);

    setData({} as AuthState);
  }, []);

  const updateLocalStorageUser = ({
    name, avatar, email, profile_class, profile_role,
  }: ILocalStorageUser) => {
    localStorage.setItem(
      LocalStorageItems.User,
      JSON.stringify({
        name, avatar, email, profile_class, profile_role,
      }),
    );
    setData((d) => ({
      ...d,
      user: {
        name, avatar, email, profile_class, profile_role,
      },
    }));
  };

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        token: data.token,
        signIn,
        signOut,
        updateLocalStorageUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('UseAuth must be used within an AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
