import React, { useState, useContext } from 'react';

import Sidebar from '../../components/Sidebar';
import MenuResponsive from '../../components/MenuResponsive/index';
import Tab from '../../components/Tab';

import SalespeopleAndStudents from './SalespeopleAndStudents';

import logo from '../../Assets/logo.png';

import { Background, Container, Main } from '../../styles/GenericStyles';

import { UserContext } from '../../UserContext';

const Financial: React.FC = () => {
    const { isBackgroundFixed } = useContext(UserContext);
    const [isSelect, setIsSelect] = useState<boolean>(true);

    return (
        <Background isBackgroundFixed={isBackgroundFixed}>
            <Container>
                <MenuResponsive />
                <Sidebar />
                <Main>
                    <img src={logo} width="152" height="76" alt="FAEL" />
                    <section>
                        <Tab
                            tab1="Vendedores"
                            tab2="Alunos"
                            isSelect={isSelect}
                            setIsSelect={setIsSelect}
                            className="tab"
                            checkout={false}
                        />
                        <SalespeopleAndStudents isSelect={isSelect} />
                    </section>
                </Main>
            </Container>
        </Background>
    );
};

export default Financial;
