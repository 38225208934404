import React from 'react';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { LoadingBarProvider } from './loadingBar';

const AppProvider: React.FC = ({ children }) => (
  <ToastProvider>
    <LoadingBarProvider>
      <AuthProvider>
        {children}
      </AuthProvider>
    </LoadingBarProvider>
  </ToastProvider>
);

export default AppProvider;
