import React, { useContext } from 'react';

import Sidebar from '../../components/Sidebar';
import MenuResponsive from '../../components/MenuResponsive/index';

import Salespeople from './Salespeople'

import logo from '../../Assets/logo.png';

import { Background, Container, Main } from '../../styles/GenericStyles';

import { UserContext } from '../../UserContext';

const Compliance: React.FC = () => {
  const { isBackgroundFixed } = useContext(UserContext);

  return (
    <Background isBackgroundFixed={isBackgroundFixed}>
      <Container>
        <MenuResponsive />
        <Sidebar />
        <Main>
          <img src={logo} width="152" height="76" alt="FAEL" />
          <section>
            <Salespeople />
          </section>
        </Main>
      </Container>
    </Background>
  );
};

export default Compliance;
