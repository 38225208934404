import api, { RawHTTPResponse, getAuthHeader, PaginatedResponse } from '../api';
import useApiCall from '../../../hooks/apiCall';
import { IUser, ProfileStatusEnum } from '../../../models/User';

interface SellersList {
    Request: {
        skip?: number;
        nameFilter?: string;
        emailFilter?: string;
        docFilter?: string;
        status?: ProfileStatusEnum;
        afterDate?: Date,
        beforeDate?: Date
    };
    Response: IUser[];
}

export const useSellersList = () => useApiCall<SellersList['Request'], SellersList['Response']>({
    request: async ({
        skip = 0,
        nameFilter = '',
        emailFilter = '',
        docFilter = '',
        status,
        afterDate,
        beforeDate
    }, token) => {
        const { data }: RawHTTPResponse<{
            sellers: SellersList['Response'],
            sellers_count: number,
            total_earnings: number
        }> = await api.get(
            '/users/sellers?'
                + (skip > 0 ? `skip=${skip}&` : '')
                + (nameFilter?.length > 0 ? `filter_name=${nameFilter}&` : '')
                + (emailFilter?.length > 0 ? `filter_email=${emailFilter}&` : '')
                + (docFilter?.length > 0 ? `filter_document_number=${docFilter}&` : '')
                + (status ? `filter_status=${status}&` : '')
                + (afterDate ? `after_date=${afterDate.toISOString()}&` : '')
                + (beforeDate ? `before_date=${beforeDate.toISOString()}` : ''),
            getAuthHeader(token)
        );
        return data.sellers;
    },
    errorMessage: {
        title: 'Erro na requisição',
        description: 'Não foi possível carregar os vendedores',
    },
});

interface UpdateSellerStatus {
    Request: {
        id: string;
        status: ProfileStatusEnum;
    };
    Response: IUser;
}

export const useUpdateSellerStatus = () => useApiCall<UpdateSellerStatus['Request'], UpdateSellerStatus['Response']>({
    request: async ({ id, status }, token) => {
        const { data }: RawHTTPResponse<UpdateSellerStatus['Response']> = await api.put(
            '/profile',
            { user_id: id, status },
            getAuthHeader(token)
        );
        return data;
    },
    errorMessage: {
        title: 'Erro na requisição',
        description: 'Não foi possível atualizar o status do vendedor',
    },
});
  