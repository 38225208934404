import React from 'react';

import { ISales } from './models'
import { formatCurrency } from '../../services/utils/format'
import close from '../../Assets/icons/close.svg';

import { ModalProfileContainer } from './styles'
import { IUser } from '../../models/User';

interface IModalProfileProps {
  isShowModal: boolean
  setIsShowModal: React.Dispatch<React.SetStateAction<boolean>>
  setIsShowTeam: React.Dispatch<React.SetStateAction<boolean>>
  sellerData: IUser | undefined
  // setListSellers: React.Dispatch<React.SetStateAction<ISales[]>>
}

const ModalProfile: React.FC<IModalProfileProps> = ({
  isShowModal,
  setIsShowModal,
  setIsShowTeam,
  sellerData
  // setListSellers
}) => {

  function handleCloseAllModal() {
    setIsShowModal(false);
    setIsShowTeam(false);
  }

  function handleShowTeam() {
    setIsShowModal(false);
    setIsShowTeam(true);
    // fazer requisição e adicionar o response => setListSellers()
  }

  return (
    <>
      <ModalProfileContainer isShow={isShowModal} className="animeTop">
        <button onClick={handleCloseAllModal} id="close" className="back-or-close"><img src={close} alt="fechar" /></button>
        <div className="modal-container">
       
            {sellerData &&   <><div className="grid-left"> <img src={sellerData.avatar} alt={`Foto de ${sellerData.name}`} />
            <button onClick={handleShowTeam}>Time de Vendas</button>

          </div>
            <div className="personal-data">
              <div>
                <label>Nome</label>
                <p>{sellerData.name|| ''}</p>
              </div>
              <div>
                <label>Email</label>
                <p>{sellerData.email || ''}</p>
              </div>
              <div>
                <label>Telefone</label>
                <p>{sellerData.phone_number|| ''}</p>
              </div>
              <div>
                <label>CPF</label>
                <p>{sellerData.document_number|| ''}</p>
              </div>
            </div></>
                
                }

</div>

   
      </ModalProfileContainer>
    </>
  );
};

export default ModalProfile
