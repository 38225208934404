import styled from "styled-components";

interface ISchedulesAndPaidContainer {
  selected: "Scheduled" | "Paid";
}

export const SchedulesAndPaidContainer = styled.div<ISchedulesAndPaidContainer>`
  .value-center {
    margin: 2.4rem 0 1.6rem;
    text-align: center;
  }
  .span {
    background-color: var(--black-background);
    border: 2px solid var(--green-primary);
    border-radius: 1rem;
    color: #fff;
    font-weight: 800;
    font-size: 1.2rem;
    line-height: 2.2rem;
    text-align: center;

    max-width: 18rem;
    height: 3.6rem;

    margin: 2px;
    @media (max-width: 600px) {
      height: 4rem;
    }
  }

  .filters-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 0.5rem;
    align-items: center;
    max-width: 86rem;
    margin: 0 auto;
    @media (max-width: 1100px) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  #buttonOn {
    background-color: var(--green-primary);
    color: black;
    border: 2px solid var(--black-background);
  }
  /* Table */
  border-spacing: 0;

  thead {
    display: flex;
    flex-direction: column;

    position: relative;

    max-width: 100%;
    margin-top: 16px;
    tr {
      font-weight: 800;
      font-size: 1.3rem;
      line-height: 1.3rem;
      display: grid;
      grid-template-columns: ${({ selected }) =>
        selected === "Scheduled"
          ? "1fr 1.3fr 1fr 0.5fr 0.8fr"
          : "1fr 1fr 1fr 1fr 0.5fr 1fr"};
      text-align: center;
    }
    
  }
  tbody {
    tr {
      display: grid;
      grid-template-columns: ${({ selected }) =>
        selected === "Scheduled"
          ? "0.8fr 1.3fr 0.7fr 0.5fr 0.7fr"
          : "1fr 1.3fr 0.1fr 0.7fr 1fr 0.7fr 1fr"};
      text-align: center;
      min-height: 4.2rem;
    }
  }
  thead tr,
    tbody tr {
      min-width: 100%;
     
    }

 

  .login-input {
    border: 1px solid green;
    height: 2rem;
    font-size: 1.2rem;
    line-height: 1.2rem;
    text-align: center;
    margin: 6px auto;
  }

  #ok {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 100%;
    background-color: var(--green-primary);
    margin: 7px 0 0 -12px;
    :disabled {
      background-color: gray;
    }
  }
`;
