import React from 'react';

import { TabContainer } from './styles';

interface ITabProps {
    tab1: string;
    tab2: string;
    isSelect: boolean;
    setIsSelect: React.Dispatch<React.SetStateAction<boolean>>;
    className?: string;
    checkout: boolean;
}

const Tab: React.FC<ITabProps> = ({ tab1, tab2, isSelect, setIsSelect, className, checkout }) => {
    function handleSelectOn() {
        setIsSelect(true)
    }
    function handleSelectOff() {
        setIsSelect(false)
    }

    return (
        <TabContainer className={className} checkout={checkout}>
            {isSelect ?
                <button onClick={handleSelectOn} className='active'>{tab1}</button>
                :
                <button onClick={handleSelectOn}>{tab1}</button>
            }
            {!isSelect ?
                <button onClick={handleSelectOff} className='active'>{tab2}</button>
                :
                <button onClick={handleSelectOff}>{tab2}</button>
            }
        </TabContainer>
    );
};

export default Tab;
