export interface IFaq {
  id: string
  category: string
  question: string
  description: string
}

export enum FaqCategoryEnum {
  receiving_and_commissions = 'Recebimento e Comissões',
  sales = 'Vendas',
  mei = 'Abertura e Cadastro da Sua Empresa',
  training_and_content = 'Aulas e Conteúdo',
  attendance = 'Canais de Atendimento',
  contact = 'Perfil e Formas de Contato',

};

export type IFaqBody = Omit<IFaq, 'is_available' | 'is_active' | 'created_at' | 'updated_at'>

export const emptyFaq: IFaqBody = {
  id: '',
  category: FaqCategoryEnum.attendance,
  question: '',
  description: ''
};