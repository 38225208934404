import React, { useState, useEffect } from 'react';

interface DataPaginatorParams<T, S extends any[]> {
    filters: S;
    storeNewData: (filters: S, skip: number) => Promise<{
        nextSkipCounter: number,
        hasReachedEnd: boolean,
        nextData: T[]
    }>,
    onError?: () => void,
    initialData?: T[]
};

type DataPaginatorReturn<T> = [
    T[],
    React.Dispatch<React.SetStateAction<T[]>>,
    boolean,
    (event: React.UIEvent<HTMLElement, UIEvent>) => void
];

type DataPaginatorHook = <T, S extends any[]>(params: DataPaginatorParams<T, S>) => DataPaginatorReturn<T>;

const useDataPaginator: DataPaginatorHook = <T, S extends any[]>({
    filters,
    storeNewData,
    onError,
    initialData = []
}: DataPaginatorParams<T, S>) => {
    const [data, setData] = useState<T[]>(initialData);
    const [skipCounter, setSkipCounter] = useState<number>(0);
    const [shouldLoadMoreData, setShouldLoadMoreData] = useState<boolean>(false);
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
    const [isEndOfList, setIsEndOfList] = useState<boolean>(false);

    const checkScrollBottom = (event: React.UIEvent<HTMLElement, UIEvent>) => {
        const {
            scrollHeight,
            scrollTop,
            clientHeight,
        } = event.currentTarget;
    
        const bottom = scrollHeight - scrollTop < clientHeight + 10;
        if (bottom) {
            setShouldLoadMoreData(true);
        } else {
            setShouldLoadMoreData(false);
        }
    };

    const loadMoreData = async (skip: number, shouldResetData: boolean) => {
        if (!isLoadingData) {   
            setIsLoadingData(true);

            try {
                const {
                    nextSkipCounter,
                    hasReachedEnd,
                    nextData
                } = await storeNewData(filters, skip);

                setIsEndOfList(hasReachedEnd);

                if (shouldResetData) {
                    setData(nextData);
                } else {
                    setData(currentData => [...currentData, ...nextData]);
                }
            
                setSkipCounter(nextSkipCounter);
            } catch (error) {
                console.log('Error loading data', { error });
                setSkipCounter(0);
                setData([]);
                onError && onError();
            } finally {
                setIsLoadingData(false);
            }
        }
    };

    useEffect(() => {
        if (shouldLoadMoreData && !isEndOfList) {
            loadMoreData(skipCounter, false);
        }
    }, [shouldLoadMoreData]);

    useEffect(() => {
        loadMoreData(0, true);
    }, [...filters]);
    
    return [data, setData, isLoadingData, checkScrollBottom];
};

export default useDataPaginator;