import React, { useEffect, useState } from 'react';

import close from '../../Assets/icons/close.svg';

import { ModalContainer } from './styles'
import { IUser, ProfileStatusEnum } from '../../models/User';

import fotoDefaultModal from '../../Assets/foto-modal.png';

import { useToast } from '../../hooks/toast';

const translateSellerStatus = (status: ProfileStatusEnum) => {
  if (status === ProfileStatusEnum.pending) return 'Pendente';
  if (status === ProfileStatusEnum.approved) return 'Aprovado';
  if (status === ProfileStatusEnum.reject) return 'Recusado';
};

interface IModalProps {
  seller: IUser | undefined,
  onModalClose: () => void,
  onNewsStatusConfirmed: (status: ProfileStatusEnum, message?: string) => Promise<boolean>
}

const Modal: React.FC<IModalProps> = ({ seller, onModalClose, onNewsStatusConfirmed }) => {
  const { addToast } = useToast();

  const [isApprove, setIsApprove] = useState<boolean>(false);
  const [isDecline, setIsDecline] = useState<boolean>(false);
  const [rejectionMessage, setRejectionMessage] = useState<string>('');

  useEffect(() => {
    setIsApprove(false)
    setIsDecline(false)
  }, [seller])

  function startApprove() { setIsApprove(true) }

  function startDecline() { setIsDecline(true) }

  const handleConfirmDecline = async () => {
    /* if (rejectionMessage.length < 10) {
      addToast({
        type: 'error',
        title: 'Dados Pendentes',
        description: 'O motivo de rejeição deve ter no mínimo 10 caracteres'
      });
      
      return;
    } */

    const res = await onNewsStatusConfirmed(ProfileStatusEnum.reject, rejectionMessage);
    if (res) {
      onModalClose();
    }
  }

  const handleConfirmApprove = async () => {
    const res = await onNewsStatusConfirmed(ProfileStatusEnum.approved);
    if (res) {
      onModalClose();
    }
  }

  const handleModalClose = () => {
    onModalClose()
  };

  return (
    <ModalContainer isShow={!!seller} className="animeTop">
      <button onClick={handleModalClose} id="close" className="back-or-close"><img src={close} alt="fechar" /></button>
      <div className="modal-container">
        <div className="grid-left">
          <img src={seller?.avatar || fotoDefaultModal} alt={`Foto de ${seller?.name}`} />
          <h3>{translateSellerStatus(seller?.status || ProfileStatusEnum.pending)}</h3>
          {!isDecline && !isApprove && seller?.status === ProfileStatusEnum.pending &&
            <div className="actions-button">
              <button onClick={startApprove} id="approve">Aprovar</button>
              <button onClick={startDecline} id="decline">Recusar</button>
            </div>
          }
          {isDecline && !isApprove &&
            <div className="actions-decline">
              {/* <textarea
                placeholder={"Insira o motivo"}
                onChange={(e) => setRejectionMessage(e.target.value)}
              /> */}
              <button onClick={handleConfirmDecline} id="isdecline" style={{ background: '#C4C4C4' }}>Recusar</button>
            </div>
          }
          {isApprove && !isDecline && <button onClick={handleConfirmApprove} id="approve">Confirmar</button>}
        </div>
        <div className="personal-data">
          <div>
            <label>Nome</label>
            <p>{seller?.name}</p>
          </div>
          <div>
            <label>Email</label>
            <p>{seller?.email}</p>
          </div>
          <div>
            <label>Telefone</label>
            <p>{seller?.phone_number}</p>
          </div>
          <div>
            <label>CPF</label>
            <p>{seller?.document_number}</p>
          </div>
          <div>
            <label>Termos de Uso</label>
            <p>{seller?.adherence_agreement ? 'Aceito' : 'Pendente'}</p>
          </div>
        </div>
      </div>
      <button type="button" id="back" onClick={handleModalClose}>Voltar</button>
    </ModalContainer >
  );
};

export default Modal