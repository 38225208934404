import React, { useState } from 'react';

import Salespeople from './Salespeople';
import Students from './Students';

import { SalespeopleAndStudentsContainer } from './styles';

interface ISalespeopleAndStudentsProps {
    isSelect: boolean
}

const SalespeopleAndStudents: React.FC<ISalespeopleAndStudentsProps> = ({ isSelect }) => {
    const [isAdd, setIsAdd] = useState<boolean>(false);

    return (
        <SalespeopleAndStudentsContainer>
            {isSelect ?
                <Salespeople />
                :
                <Students isAdd={isAdd} setIsAdd={setIsAdd} />
            }
        </SalespeopleAndStudentsContainer>
    )
}

export default SalespeopleAndStudents;
