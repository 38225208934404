import styled from 'styled-components'


export const Container = styled.div`
   thead tr {
        font-weight: 800;
        font-size: 1.3rem;
        line-height: 1.3rem;
        margin-top: 13px;
    }
    thead tr,
    tbody tr {
        min-width: 100%;
        @media(max-width: 700px) {
            min-width: 740px;
        }
    }
    display: flex;
    flex-direction: column;

    position: relative;
    max-width: 100%;
    margin-top: -10px;
    padding-bottom: 8px;
`;


export const SalespeopleAndStudentsContainer = styled.div`
    .value-center {
        display: flex;
        justify-content: center;
        margin: 2.4rem 0 1.6rem;
        div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            margin: 0 .8rem;
            span {
                background-color: var(--black-background);
                border: 2px solid var(--green-primary);
                border-radius: 10px;
                color: #fff;
                font-weight: 800;
                font-size: 1.2rem;
                line-height: 2.2rem;
                text-align: center;

                max-width: 18rem;
                max-height: 3.6rem;
                padding: .8rem 3.2rem;
            }
            p {
                color: var(--black-background);
                font-weight: 800;
                font-size: 1.5rem;
                line-height: 1.5rem;
                margin-left: .4rem;
            }
        }
    }

    /* Salespeople */

    .filters-container-salespeople {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: .5rem;
        max-width: 60rem;
        align-items: center;
        margin: 0 auto;
        text-align: center;
        @media(max-width: 540px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    /* Tr Salespeople */
    .tr-salespeople {
        tr {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            text-align: center;
            align-items: center;

            @media(max-width: 1220px) {
                max-width: 80rem;
            }
            @media(max-width: 1160px) {
                max-width: 70rem;
            }
            @media(max-width: 800px) {
                max-width: 60rem;
            }
        }
        .tr-salespeople-background {
            &:hover {
                background-color: #eee;
                border-radius: 1rem;
                
                cursor: pointer;
            }
        }
    }

    /* Students */

    .filters-container-students {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: .5rem;
        align-items: center;
        max-width: 86rem;
        margin: 3.2rem 100% 1.6rem 22.5%;
        @media (max-width: 1200px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        button {
            background-color: var(--black-background);
            border: 2px solid var(--green-primary);
            border-radius: 10px;
            color: #fff;
            font-weight: 800;
            font-size: 1rem;
            line-height: 1.2rem;
            text-align: center;
            text-transform: uppercase;
            vertical-align: middle;

            height: 3.6rem;
            padding: 1rem 1.6rem .5rem;
            cursor: pointer;
            &:hover,
            &:focus {
                outline: none;
                box-shadow: 0 0 0 1.5px var(--green-primary);
            }
        }
        .selected {
            background-color: var(--green-primary);
            color: var(--black-background);
            &:hover,
            &:focus {
                box-shadow: none;
            }
        }
    }

    /* Tr Students */
    .tr-students {
        tr {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            text-align: center;
            align-items: center;

        }
    }
`

interface IModalGenericContainerProps {
    isShow: boolean
}

export const ModalProfileContainer = styled.div<IModalGenericContainerProps>`
    background: var(--black-background);
    border: 1px solid var(--green-primary);
    border-radius: 1rem;
    box-sizing: border-box;
    color: var(--black-background);
    display: ${(props) => (props.isShow ? 'block' : 'none')};

    padding: 1.6rem 3.2rem;

    position: absolute;
    position:fixed;
    left: 50%;
    top: 50%;
    width: 66rem;
    height: 50rem;
    margin-left:-33rem;
    margin-top:-25rem;
    @media (max-width: 680px) {
        width: 50rem;
        height: 70rem;
        margin-left:-25rem;
        margin-top: -35rem;
        z-index: 20;
    }
    @media (max-width: 500px) {
        max-width: 90vw;
        max-height: 94vh;
        margin-left: -45vw;
        margin-top: -47vh;
    }


    #close {
        float: right;
    }
    .modal-container {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 2rem;
        align-items: center;
        justify-content: center;
        @media (max-width: 680px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1;
        }
        .grid-left {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            
            height: 100%;
            img {
                max-width: 20rem;
            }
            @media (max-width: 680px) {
                height: 30rem;
                img {
                    max-width: 17rem;
                }
            }
            @media (max-width: 370px) {
                height: 26rem;
                img {
                    max-width: 12rem;
                }
            }
            button {
                background: var(--green-primary);
                border: 1px solid var(--green-primary);
                border-radius: 1rem;
                font-weight: 800;
                font-size: 1.8rem;
                line-height: 1.9rem;
                text-align: center;

                width: 17.2rem;
                height: 4rem;
                padding: 1rem 0 .4rem;
                cursor: pointer;
                @media (max-width: 640px) {
                    font-size: 1.6rem;
                    line-height: 1.6rem;
                    padding: 1.2rem 0 .4rem;

                }
                &:hover,
                &:focus {
                    outline: none;
                    box-shadow: 0 0 0 2px var(--green-primary);
                }
            }
            h3 {
                background: #fff;
                border: 1px solid #fff;
                border-radius: 1rem;
                color: var(--black-background);
                font-weight: 800;
                font-size: 1.8rem;
                line-height: 1.8rem;
                vertical-align: middle;
                text-align: center;

                width: 17.2rem;
                height: 4rem;
                padding-top: 1.4rem;
            }
        }
        .personal-data {
            display: flex;
            flex-direction: column;
            p {
                background: #fff;
                border-radius: 1rem;
                font-weight: 800;
                font-size: 1.8rem;
                line-height: 1.9rem;

                padding: 1.2rem 1.2rem .4rem;
                margin-bottom: 1.6rem;
                @media (max-width: 680px) {
                    font-size: 1.6rem;
                    line-height: 1.6rem;

                    padding: 1.2rem 1.2rem .6rem;
                    margin-bottom: 1.2rem;
                    min-width: 100%;
                }
                @media (max-width: 370px) {
                    font-size: 1.4rem;
                    line-height: 1.4rem;

                    padding: .8rem;
                    margin-bottom: 1rem;
                }
            }
            label {
                color: #fff;
                font-weight: 800;
                font-size: 1.6rem;
                line-height: 2.4rem;
                @media (max-width: 640px) {
                    font-size: 1.4rem;
                    line-height: 2rem;
                }
                @media (max-width: 370px) {
                    font-size: 1.2rem;
                    line-height: 1.8rem;
                }
            }
        }
    }
    .back-or-close {
        background-color: transparent;
        border: none;
        color: #fff;
        font-size: 1.3rem;
        font-weight: 800;

        cursor: pointer;
        outline: none;
    }
    .photo-list-team {
        max-width: 3rem;
    }
`;

export const ModalListSellersContainer = styled.div<IModalGenericContainerProps>`
    background: var(--black-background);
    border: 1px solid var(--green-primary);
    border-radius: 1rem;
    box-sizing: border-box;
    color: var(--black-background);
    display: ${(props) => (props.isShow ? 'block' : 'none')};
    padding: 1.6rem 3.2rem;

    position: absolute;
    position:fixed;
    left: 50%;
    top: 50%;
    width: 66rem;
    height: 50rem;
    margin-left:-33rem;
    margin-top:-25rem;
    @media (max-width: 640px) {
        width: 50rem;
        margin-left:-25rem;
    }
    @media (max-width: 500px) {
        max-width: 90vw;
        margin-left: -45vw;
        margin-top:-45vh;
        z-index: 10;
    }
    @media (max-width: 370px) {
        min-height: 90vh;
        margin-top: -48vh;
    }
    .button-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    table {
        border-spacing: 0;
        color: #fff;
        font-size: 1.2rem;
        
        display: block;
        position: relative;

        max-width: 100%;
        max-height: 40rem;
        padding-bottom: .8rem;
        margin-top: 3.6rem;
        overflow: auto;
        ::-webkit-scrollbar-track {
            background-color: #c4c4c4;
            border-radius: 2rem;
        }
        ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            @media (max-width: 800px) {
                width: 4px;
                height: 4px;
            }
        }
        ::-webkit-scrollbar-thumb {
            background: #646464;
            border-radius: 2rem;
        }

        tr {
            display: grid;
            grid-template-columns: 0.5fr repeat(5, 1fr);
            width: 58rem;
            margin: 0 auto;
            text-align: center;
            align-items: center;
        }
        thead {
            margin-bottom: 1.6rem;
        }

        tbody {
            tr {
                padding: .8rem 0;
            }
            button {
                background-color: transparent;
                border: none;
                color: #fff;
                font-size: 1.3rem;
                font-weight: 300;
                text-decoration: underline;
                cursor: pointer;
                outline: none;
            }
        }
    }
    .back-or-close {
        background-color: transparent;
        border: none;
        color: #fff;
        font-size: 1.3rem;
        font-weight: 800;

        cursor: pointer;
        outline: none;
    }
    .photo-list-team {
        max-width: 3rem;
    }
`