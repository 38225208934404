export const phoneNumberMask = (input: string): string => {
  const digitsOnlyInput = input.replace(/\D/g, '');

  let value = digitsOnlyInput
    .replace(/(\d{2})(\d)/, '$1) $2');

  if (digitsOnlyInput.length > 10) {
    value = value.replace(/(\d{5})(\d)/, '$1-$2');
  } else {
    value = value.replace(/(\d{4})(\d)/, '$1-$2');
  }

  return '('
    .concat(value)
    .replace(/(-\d{4})\d*$/, '$1');
};

interface IDocumentNumberMaskOptions {
  allowCompany?: boolean;
}
export const documentNumberMask = (
  input: string,
  { allowCompany = true }: IDocumentNumberMaskOptions = {},
): string => {
  const digitsOnlyInput = input.replace(/\D/g, '');

  let value = '';
  if (digitsOnlyInput.length > 11 && allowCompany) {
    value = digitsOnlyInput
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
      .replace(/(\d{2})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3/$4')
      .replace(/(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})(\d)/, '$1.$2.$3/$4-$5')
      .replace(/(-\d{2})\d*$/, '$1');
  } else {
    value = digitsOnlyInput
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})\.(\d{3})(\d)/, '$1.$2.$3')
      .replace(/(\d{3})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3-$4')
      .replace(/(-\d{2})\d*$/, '$1');
  }

  return value;
};

export const postalCodeMask = (input: string): string => {
  const digitsOnlyInput = input.replace(/\D/g, '');

  return digitsOnlyInput
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d*$/, '$1');
};

export const bankAgencyMask = (input: string): string => input
  .replace(/\D/g, '')
  .replace(/(\d{4})\d*$/, '$1');

export const bankAccountMask = (input: string): string => {
  const digitsOnlyInput = input.replace(/\D/g, '');

  return digitsOnlyInput.replace(/(\d*)(\d{1})/, '$1-$2');
};
