import React, { useEffect, useState } from 'react';

import Input from '../../components/Input';
import ModalProfile from './ModalProfile'
import ModalListSellers from './ModalListSellers'

import { ISales } from './models'
import { formatCurrency } from '../../services/utils/format'

import fotoModal from '../../Assets/foto-modal.png';

import { Table } from '../../styles/GenericStyles';
import useDataPaginator from '../../hooks/dataPaginator';
import useDebouncedText from '../../hooks/debouncedText';
import { GetSellerList, useGetSellerList } from '../../services/api/clients';
import { IUser, ProfileClassEnum } from '../../models/User';
import DatePicker from '../../components/DatePicker';
import { Container } from './styles';
import { format, parseISO } from 'date-fns';

const Salespeople: React.FC = () => {
  
    const [listSellers, setListSellers] = useState<ISales[]>([
        {
            id: "erika09092020",
            name: "Erika",
            level: "Pleno",
            team: "Roberto D",
            sales: 12,
            commissions: 5667,
            date: "09/09/2020",
            email: 'erikamiranda.kok@gmail.com',
            phone: '(41) 92622-8922',
            cpf: '062.659.695-54',
            photo: `${fotoModal}`,
            social: 'facebook.com/erikaMiranda123665',
        },
        {
            id: "joao09092021",
            name: "João",
            level: "Senior",
            team: "Roberto D",
            sales: 3,
            commissions: 10260,
            date: "09/09/2020",
            email: 'joao.kok@gmail.com',
            phone: '(41) 99999-4567',
            cpf: '062.659.888-33',
            photo: `${fotoModal}`,
            social: 'facebook.com/joao123665',
        }
    ])
    const [sellerData, setSellerData] = React.useState<IUser>();
    const [isShowModal, setIsShowModal] = React.useState(false);
    const [isShowTeam, setIsShowTeam] = React.useState(false);
    const getSellerList = useGetSellerList();

    const [dateRange, setDateRange] = useState<Date[]>(
        [new Date(), new Date(new Date().setDate(new Date().getDate() + 1))],
      );
    const [filterName, setFilterName] = useDebouncedText('');
    const [filterLevel, setFilterLevel] = useDebouncedText('');

    const [data, setData] = useState<GetSellerList['Response']>()

    // const [
    //     data,
    //     setData,
    //     dataListLoading,
    //     checkDataListBottom,
    //   ] = useDataPaginator<GetSellerList['Response'], [Date[],string, string]>({
    //     filters: [dateRange ,filterName, filterLevel],
    //     storeNewData: async (filters, cursor) => {
          
    //       const res = await getSellerList({ 
    //         // cursor: cursor as string,
    //         after_date: filters[0][0].toISOString(),
    //         before_date: filters[0][1].toISOString(),
    //         filter_name: filters[1],
    //         filter_level: filters[2],
    //         filter_status: 'Approved'
    //       });
    //       console.log('---<<>>', res);
    //       return {
    //         nextCursor: res.cursor.afterCursor,
    //         shouldResetData: !res.cursor.beforeCursor,
    //         nextData: res.data,
    //       };
    //     },
    //   });

    useEffect(()=>{
         (async () => {
             try {
                const res = await getSellerList({
                    after_date: dateRange[0].toISOString(),
                    before_date: dateRange[1].toISOString(),
                    filter_level: filterLevel,
                    filter_name: filterName,
                    filter_status: 'Approved'
                })
                setData(res)
             } catch (error) {
                 
             }
        })()
    },[dateRange, filterLevel, filterName])

    return (
        <Container>
            <div className="value-center">
                <div>
                    <p>Vendedores</p>
                    <span>{data?.sellers_count || 0}</span>
                </div>
                <div>
                    <p>Comissões</p>
                    <span>{formatCurrency(data?.total_earnings || 0)}</span>
                </div>
            </div>
            <div className="filters-container-salespeople">
                <Input type="text" placeholder="Name"
                onChange={(e)=>setFilterName(e.target.value)}
                />
                <Input type="text" placeholder="Nível"
                onChange={(e)=>setFilterLevel(e.target.value)}

                />
                <DatePicker dateRange={dateRange} setDateRange={setDateRange} />
            </div>
            <thead className="tr-salespeople">
                    <tr>
                        <th>Vendedor</th>
                        <th>Nível</th>
                        <th>Time</th>
                        <th>Comissões</th>
                        <th>Adesão</th>
                    </tr>
            </thead>

            <Table >
                <tbody className="tr-salespeople">
                    {data?.sellers.map((item) => (
                        <tr key={item.id}
                            onClick={() => {
                                setIsShowModal(true);
                                setSellerData(item)
                            }}
                            className="tr-salespeople-background"
                        >
                            <td>{item.name}</td>
                            <td>{item.profile_class}</td>
                            <td>{item.referrer_name}</td>
                            <td>{formatCurrency(item.seller_earnings || 0)}</td>
                            <td>{format(parseISO(item.created_at), 'dd/MM/yyyy')}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <ModalProfile
                isShowModal={isShowModal}
                setIsShowModal={setIsShowModal}
                setIsShowTeam={setIsShowTeam}
                sellerData={sellerData}
            // setListSellers={setListSellers}
            />
            <ModalListSellers
                setIsShowModal={setIsShowModal}
                isShowTeam={isShowTeam}
                setIsShowTeam={setIsShowTeam}
                listSellers={listSellers}
            />
        </Container>
        
    )
}

export default Salespeople;
