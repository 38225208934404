import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from '../pages/Login';
import Home from '../pages/Home';
import Financial from '../pages/Financial';
import Course from '../pages/Course'
import Client from '../pages/Client'
import Compliance from '../pages/Compliance'
import Faq from '../pages/Faq'
import Training from '../pages/Training'
import NotFound from '../pages/NotFound';
import { useAuth } from '../hooks/auth';

const Routes: React.FC = () => {
    const { user } = useAuth();

    const publicRoute = (Component: React.FC) => (user
        ? () => <Redirect to="/home" />
        : Component
    );

    const privateRoute = (Component: React.FC) => (user
        ? Component
        : () => <Redirect to="/" />
    );

    return (
        <Switch>
            <Route
                path="/"
                exact
                component={publicRoute(Login)}
            />
            <Route
                path="/home"
                exact
                component={privateRoute(Home)}
            />
            <Route
                path="/financial"
                exact
                component={privateRoute(Financial)}
            />
            <Route
                path="/course"
                exact
                component={privateRoute(Course)}
            />
            <Route
                path="/client"
                exact
                component={privateRoute(Client)}
            />
            <Route
                path="/compliance"
                exact
                component={privateRoute(Compliance)}
            />
            <Route
                path="/faq"
                exact
                component={privateRoute(Faq)}
            />
            <Route
                path="/training"
                exact
                component={privateRoute(Training)}
            />
            <Route
                path="/404"
                component={NotFound}
            />

            <Route
                path="*"
                component={() => <Redirect to="/404" />}
            />
        </Switch>
    );
};

export default Routes;
