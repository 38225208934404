import React from 'react';

interface IProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    onChange(value: boolean): void;
}

export const Checkbox: React.FC<IProps> = ({ children,
    onChange, ...shared }) => {
    return <input onChange={e => onChange(e.target.checked)} {...shared} />
}

Checkbox.defaultProps = {
    type: 'checkbox',
}