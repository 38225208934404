import React, { useCallback, useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import getValidationError from "../../services/utils/getValidationErrors";

import {
  ICourseBody,
  emptyCourse,
  CourseCategoryEnum,
} from "../../models/Course";

import close from "../../Assets/icons/close.svg";
import cam from "../../Assets/icons/cam.svg";

import { ModalContainer, InputPhoto } from "./styles";
import { useDeleteCourse } from "../../services/api/courses";
import { useToast } from "../../hooks/toast";
import { validate } from "uuid";

interface IModal {
  isOpenModal: boolean;
  onRequestClose: () => void;
  thisCourse: ICourseBody | undefined;
  handleCourse: (course: ICourseBody, imgFile: File) => Promise<boolean>;
}

const Modal: React.FC<IModal> = ({
  isOpenModal,
  onRequestClose,
  handleCourse,
  thisCourse,
}) => {
  const { addToast } = useToast();

  const [imgFile, setImgFile] = useState<File>(new File([], ""));
  const [imgSource, setImgSource] = useState<string>("");
  const [course, setCourse] = useState<ICourseBody>(thisCourse || emptyCourse);
  const formRef = useRef<HTMLFormElement>(null);

  const deleteCourse = useDeleteCourse();
  // console.log({ imgFile })
  // console.log({ imgSource })
  // console.log({ course })

  useEffect(() => {
    setCourse(thisCourse || emptyCourse);
    setImgSource(thisCourse?.picture || "");
    setImgFile(new File([], ""));
  }, [thisCourse]);

  function handleClick() {
    const inputId = document.getElementById("files");
    inputId?.click();
  }

  function handleInputFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target!.result;
        setImgSource(result as string);
        setCourse((currentCourse) => ({
          ...currentCourse,
          picture: result as string,
        }));
        console.log({ result });
      };
      reader.readAsDataURL(event.target.files[0]);
      setImgFile(event.target.files[0]);
    }
  }

  function handleCloseModal() {
    onRequestClose();
    setImgSource("");
    setImgFile(new File([], ""));
    formRef && formRef.current && formRef.current.reset();
  }

  const validateImgFile = (img: File) =>
    Number(img.size) <= 3000000 && img.type.startsWith("image");

  const addNewCourse = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      try {
        const schema = Yup.object().shape({
          picture: Yup.string().required("A foto do curso é obrigatório"),
          name: Yup.string().required("O nome do curso é obrigatório"),
          description: Yup.string().required(
            "A descrição do curso é obrigatório"
          ),
          price: Yup.number().required("O preço cheio do curso é obrigatório"),
          promotional_price: Yup.number().required(
            "O preço promocional do curso é obrigatório"
          ),
          category: Yup.string().required("O tipo de curso é obrigatório"),
        });

        await schema.validate(course, { abortEarly: false });

        const isValidImageSelected =
          course.picture.length > 0 &&
          (course.id.length > 0
            ? imgFile.name.length === 0 || validateImgFile(imgFile)
            : validateImgFile(imgFile));

        if (!isValidImageSelected) {
          addToast({
            type: "error",
            title: "Erro nos dados informados",
            description: "Selecione uma imagem válida (máx. 3MB)",
          });
          return;
        }

        const courseSaved = await handleCourse(course, imgFile); //fazer verificação

        if (courseSaved) {
          handleCloseModal();
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const firstErrorMessage = error.errors[0];
          addToast({
            type: "error",
            title: "Erro nos dados informados",
            description: firstErrorMessage,
          });
          return;
        }
      }
    },
    [course, handleCourse, onRequestClose]
  );

  const handleDeletion = async () => {
    if (thisCourse?.id) {
      try {
        const courseSaved = await deleteCourse({ id: thisCourse.id }); //fazer verificação
        if (courseSaved) {
          handleCloseModal();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <ModalContainer isOpenModal={isOpenModal} className="animeTop">
      <button id="close" onClick={handleCloseModal}>
        <img src={close} alt="fechar modal" />
      </button>
      <form ref={formRef} className="modal-container" onSubmit={addNewCourse}>
        <fieldset className="grid-left">
          <InputPhoto imgSource={imgSource}>
            <button
              type="button"
              onClick={handleClick}
              className="background-image"
            >
              {imgSource ? (
                <img src={cam} alt="Trocar Foto" />
              ) : (
                <label>
                  Clique aqui
                  <p>Para adicionar uma imagem no curso.</p>
                </label>
              )}

              <input
                type="file"
                name="files"
                id="files"
                onChange={handleInputFileChange}
                hidden
              />
            </button>
          </InputPhoto>
          <div className="description-container">
            <label htmlFor="description">Descrição</label>
            <textarea
              id="description"
              name="description"
              placeholder="Digite aqui a descrição do curso"
              value={course.description}
              onChange={(e) =>
                setCourse((c) => ({ ...c, description: e.target.value }))
              }
            />
          </div>
        </fieldset>
        <fieldset className="grid-right">
          <div className="input-data">
            <label>Categoria</label>
            {course && <select
              value={    [...Object.values(CourseCategoryEnum)].includes(
                course.category,
              )
                ? course.category
                : 'NULL'}
              onChange={(e) => {
                const category = e.target.value as CourseCategoryEnum;
                setCourse((c) => ({ ...c, category }))
              }}
            >
              <option value="NULL" disabled>
                    Selecione
                  </option>
              {Object.values(CourseCategoryEnum).map((cat) => (
                <option key={cat} value={cat}>
                  {cat}
                </option>)
              )}
            </select>}
          </div>
          <div className="input-data">
            <label>Curso</label>
            <input
              type="text"
              value={course.name}
              onChange={(e) =>
                setCourse((c) => ({ ...c, name: e.target.value }))
              }
            />
          </div>
          <div className="input-data">
            <label>Preço Cheio</label>
            <input
              type="number"
              value={course.price}
              onChange={(e) =>
                setCourse((c) => ({ ...c, price: Number(e.target.value) }))
              }
            />
          </div>
          <div className="input-data">
            <label>Preço Promocional</label>
            <input
              type="number"
              value={course.promotional_price}
              onChange={(e) =>
                setCourse((c) => ({
                  ...c,
                  promotional_price: Number(e.target.value),
                }))
              }
            />
          </div>
        </fieldset>
        {thisCourse?.id && (
          <button
            type="button"
            onClick={() => handleDeletion()}
            className="delete"
          >
            Deletar
          </button>
        )}
        <button type="submit" className="confirm">
          {thisCourse ? "Atualizar" : "Confirmar"}
        </button>
      </form>
    </ModalContainer>
  );
};

export default Modal;
