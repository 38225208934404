import styled from 'styled-components'

export const FaqContainer = styled.div`
    background-color: #fff;
    position: relative;
    /* max-width: 86rem; */
    .intro-container {
        display: flex;
        align-items: center;
        color: var(--black-background);
        max-width: 26rem;
        margin: 0 auto 3.2rem;
        
        h1 {
            font-weight: 800;
            font-size: 2.4rem;
            line-height: 2.4rem;
        }
    }
    .search-and-add {
        display: flex;
        justify-content: flex-end;

        margin: 3.2rem 0 1.6rem;
        button {
            background-color: var(--black-background);
            border: 2px solid var(--green-primary);
            border-radius: 1rem;
            color: #fff;
            font-weight: 800;
            font-size: 1.2rem;
            line-height: 1.2rem;
            text-align: center;
            vertical-align: middle;

            height: 3.6rem;
            margin-left: .8rem;
            padding: .8rem 2rem .4rem;
            cursor: pointer;
            &:hover,
            &:focus {
                outline: none;
                box-shadow: 0 0 0 1.5px var(--green-primary);
            }
        }
        @media (max-width: 600px) {
            justify-content: center;
            button {
                height: 4rem;
            }
        }
    }
    h3 {
        color: var(--black-background);
        font-size: 2.4rem;
        font-weight: normal;
        line-height: 2.4rem;
        @media (max-width: 600px) {
            font-size: 2.2rem;
            line-height: 2.2rem;
            text-align: center;
            order: 2;
        }
    }
    .sales-attendance {
        width: 100%;
        max-height: 20rem;
        margin: 2rem 0 3rem;
        padding: 0 1rem;
        overflow: auto;
        @media (max-width: 600px) {
            max-height: 26rem;
        }
        ::-webkit-scrollbar-track {
            background-color: #c4c4c4;
            border-radius: 2rem;
        }
        ::-webkit-scrollbar {
            width: .7rem;
        }
        ::-webkit-scrollbar-thumb {
            background: #646464;
            border-radius: 2rem;
        }
    }

    .search-container {
        display: flex;
        justify-content: space-between;
    }
`

interface IModalContainerProps {
    isOpenModal: boolean
}

export const ModalContainer = styled.div<IModalContainerProps>`
    display: ${({ isOpenModal }) => isOpenModal ? 'block' : 'none'};
    
    background: var(--black-background);
    border: 1px solid var(--green-primary);
    border-radius: 1rem;
    color: var(--black-background);
    
    padding: 3.2rem 5.2rem;

    position: fixed;
    left: 50%;
    top: 50%;

    width: 60rem;
    height: 46rem;
    margin-left:-30rem;
    margin-top:-23rem;
    #close {
        background-color: transparent;
        border: none;
        position: absolute;
        top: .4rem;
        right: 0;

        outline: none;
        cursor: pointer;
    }
    @media (max-width: 640px) {
        width: 50rem;
        margin-left: -25rem;
    }
    @media (max-width: 500px) {
        max-width: 90vw;
        height: 90vh;
        margin-left: -45vw;
        margin-top: -45vh;
        z-index: 10;
    }
    @media (max-width: 370px) {
        min-height: 90vh;
        margin-top: -48vh;
    }
    form {
        position: relative;
        @media (max-width: 500px) {
            height: 88%;
        }
    }
    .modal-container {
        .fieldset-container {
            display: grid;
            grid-template-columns: 2fr 1fr;
            gap: 1.6rem;

            position: relative;
            @media(max-width: 500px) {
                display: flex;
                flex-direction: column;    
            }
        }
        fieldset {
            border: none;
        }
        label {
            color: #fff;
            font-size: 1.2rem;
            font-weight: 800;

            margin-bottom: .6rem;
        }
        .input-data {
            display: flex;
            flex-direction: column;
            flex: 1;
            input,
            select,
            textarea {
                background: var(--black-background);
                border: 1px solid var(--green-primary);
                border-radius: 1rem;
                color: #fff;

                height: 4rem;
                padding-left: .4rem;
                margin-bottom: 1rem;
                outline: none;
                &:hover,
                &:focus {
                    outline: none;
                    box-shadow: 0 0 0 1px var(--green-primary);
                }
            }
            input[type=number]::-webkit-inner-spin-button, 
            input[type=number]::-webkit-outer-spin-button { 
                -webkit-appearance: none;
            }
            textarea {
                height: 18rem;
                padding-top: 1rem;
                margin-bottom: 6rem;
                resize: none;
            }
        }
        .confirm {
            background: var(--green-primary);
            border: 1px solid var(--green-primary);
            border-radius: 1rem;
            color: var(--black-background);
            font-weight: 800;
            
            height: 4rem;

            width: 23rem;
            position: absolute;
            bottom: -6rem;
            right: 0;
            outline: none;
            cursor: pointer;
            &:hover,
            &:focus {
                outline: none;
                box-shadow: 0 0 0 1px var(--green-primary);
            }
            @media(max-width: 500px) {
                width: 100%;
            }
        }
        .delete {
            background: red;
            border: 1px solid red;
            border-radius: 1rem;
            color: black;
            font-weight: 900;
            
            height: 4rem;
            min-height: 4rem;

            width: 23rem;
            position: absolute;
            bottom: -1rem;
            right: 0;

            outline: none;
            cursor: pointer;
            @media (max-width: 640px) {
                position: relative;
            }
            @media (max-width: 500px) {
                width: 100%;
            }
            &:hover,
            &:focus {
                outline: none;
                box-shadow: 0 0 0 1px red;
            }
        }
    }
`