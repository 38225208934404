import React from 'react';
import { useLocation } from 'react-router-dom';

type ContextValue = {
    isBackgroundFixed: boolean;
    setIsBackgroundFixed?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const UserContext = React.createContext<ContextValue>({
    isBackgroundFixed: false,
    setIsBackgroundFixed: () => { }
});

export const UserProvider: React.FC = ({ children }) => {
    const [isBackgroundFixed, setIsBackgroundFixed] = React.useState<boolean>(false);

    const location = useLocation();
    const { pathname } = location;

    React.useEffect(() => {
        setIsBackgroundFixed(false);
    }, [pathname]);

    return (
        <UserContext.Provider
            value={{
                isBackgroundFixed,
                setIsBackgroundFixed
            }}>
            {children}
        </UserContext.Provider>
    );
};
