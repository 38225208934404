/* eslint-disable no-shadow */
export enum ProfileRoleEnum {
    seller = 'Seller',
    admin = 'Admin'
}

export enum ProfileClassEnum {
    trainee = 'Trainee',
    junior = 'Junior',
    pleno = 'Pleno',
    master = 'Master',
    master_supervisor = 'Master Supervisor'
}

export enum ProfileStatusEnum {
    pending = 'Pending',
    approved = 'Approved',
    reject = 'Rejected',
}

export interface IUser {
    id: string;
    name: string;
    email: string;
    phone_number: string;
    document_number: string;
    birth_date: string | null;
    bank: string | null;
    bank_account: string | null;
    bank_agency: string | null;
    postal_code: string | null;
    state: string | null;
    city: string | null;
    neighborhood: string | null;
    street: string | null;
    complement: string | null;
    profile_role: ProfileRoleEnum;
    profile_class: ProfileClassEnum | null;
    status: ProfileStatusEnum;
    adherence_agreement: boolean;
    seller_earnings: number | null;
    avatar: string | undefined;
    referrer_name: string;
    is_active: boolean;
    created_at: string;
    updated_at: string;
}

export type ILocalStorageUser = Pick<IUser, 'email' | 'name' | 'avatar' | 'profile_class'| 'profile_role'>;
