export const getCurrencyMask = (rawCents: number | string): string => {
  const cents = Number(rawCents);
  const floatValue = cents / 100;
  const textValue = floatValue
    .toFixed(2)
    .replace('.', ',');

  return `R$ ${textValue}`;
};

export const getRawCurrency = (text: string): number => {
  // Expected format: 'R$ 24,67'
  const floatText = text.replace('R$ ', '');
  const floatValue = Number(floatText.replace(',', '')) || 0;
  const centsValue = Number(floatValue.toFixed(0));

  return centsValue;
};
