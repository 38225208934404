import React from 'react';
import { IStatus } from '../../pages/Client/models';

import { SelectContainer } from './styles';

interface ISelectProps {
    thisForm: boolean
    value: IStatus[]
    onChange: (value: string) => void
};
const Select: React.FC<ISelectProps> = (props) => {
    return (
        <SelectContainer thisForm={props.thisForm} onChange={e => props.onChange(e.target.value)}>
            {props.value.map((item) => (
                <option
                    key={item.id}
                    value={item.value}
                >
                    {item.name}
                </option>
            ))}
        </SelectContainer>
    );
};

export default Select;
