import styled from 'styled-components';

interface INavProps {
  open: boolean
}

export const Nav = styled.nav<INavProps>`
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  padding: 20px 0;
  position: fixed;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-150%)')};
  z-index: 1;
  @media (max-width: 420px) {
    padding-bottom: 30px;
  }
  .nav-link {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;

    &:focus {
      outline: none;
    }
    img {
      margin-right: 8px;
      max-width: 2.4rem;
      min-width: 2.4rem;
    }
  }

  .nav-link:hover {
    color: var(--green-primary);
    transition: 200ms;
  }

  .active {
    color: var(--green-primary);
  }
  .duvidas {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    .nav-link {
      margin-top: 20px;
    }
  }
`;
