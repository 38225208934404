import React from 'react';

import { formatCurrency } from '../../services/utils/format'
import { ISales } from './models';

import close from '../../Assets/icons/close.svg';

import { ModalListSellersContainer } from './styles'

interface IModalListSellersProps {
  setIsShowModal: React.Dispatch<React.SetStateAction<boolean>>
  isShowTeam: boolean
  setIsShowTeam: React.Dispatch<React.SetStateAction<boolean>>
  listSellers: ISales[]
}

const ModalListSellers: React.FC<IModalListSellersProps> = ({
  setIsShowModal,
  isShowTeam,
  setIsShowTeam,
  listSellers }) => {

  function handleCloseAllModal() {
    setIsShowModal(false);
    setIsShowTeam(false);
  }

  function handleHideTeam() {
    setIsShowModal(true);
    setIsShowTeam(false);
  }

  function handleShowModalOtherProfile() {
    setIsShowTeam(false);
    setIsShowModal(true);
    console.log("Voltar para o modal anterior e mostrar o perfil de outro vendedor")
  }

  return (
    <>
      <ModalListSellersContainer isShow={isShowTeam} >
        <div className="button-container">
          <button onClick={handleHideTeam} className="back-or-close">Voltar</button>
          <button onClick={handleCloseAllModal} className="back-or-close"><img src={close} alt="fechar" /></button>
        </div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Nome</th>
              <th>Nível</th>
              <th>Vendas</th>
              <th>Comissões</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {/* dentro do objeto sellerData 'team' é um array? */}
            {listSellers.map((item) => (
              <tr>
                <td><img src={item.photo} alt={`Foto de ${item.name}`} className="photo-list-team" /></td>
                <td>{item.name}</td>
                <td>{item.level}</td>
                <td>{item.sales}</td>
                <td>{formatCurrency(item.commissions)}</td>
                <td><button onClick={handleShowModalOtherProfile}>Ver perfil</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </ModalListSellersContainer>
    </>
  );
};

export default ModalListSellers
