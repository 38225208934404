import { IUser } from './../../../models/User';
import api, { RawHTTPResponse, getAuthHeader, PaginatedResponse } from '../api';
import useApiCall from '../../../hooks/apiCall';
import { ISale } from '../../../models/Sale';
import { IStudentEnrollmentList } from '../../../models/Student';
import { AxiosResponse } from 'axios';

interface GetStudentList {
    Request: {
        filter_course_name?: string;
        filter_name?: string;
        filter_seller_name?:string;
        cursor?: string;
    };
    Response: PaginatedResponse<IStudentEnrollmentList>;
}
  
export const useGetStudentList = () => useApiCall<GetStudentList['Request'], GetStudentList['Response']>({
    request: async ({
        filter_course_name,
        filter_name,
        filter_seller_name,
        cursor,
        }, token) => {
        const { data }: RawHTTPResponse<GetStudentList['Response']> = await api.get(
            ''.concat(
                `/student?`,
                filter_course_name ? `&filter_course_name=${filter_course_name}` : '',
                filter_name ? `&filter_name=${filter_name}` : '',
                filter_seller_name ? `&filter_seller_name=${filter_seller_name}` : '',
                cursor ? `&after_cursor=${cursor}` : ''
            ),
          getAuthHeader(token)
        );
        return data;
    },
    errorMessage: {
        title: 'Erro na requisição',
        description: 'Não foi possível carregar as vendas pagas',
    },
});

export interface GetSellerList {
    Request: {
        filter_level?: string;
        filter_name?: string;
        after_date: string;
        filter_status?: string;
        before_date: string;
        // cursor?: string;
    };
    Response:  {
        sellers: IUser[];
        sellers_count: number;
        total_earnings: number;
    };
}
  
export const useGetSellerList = () => useApiCall<GetSellerList['Request'], GetSellerList['Response']>({
    request: async ({
        filter_level,
        filter_name,
        after_date,
        before_date,
        filter_status,
        // cursor,
        }, token) => {
        const { data }: RawHTTPResponse<GetSellerList['Response']> = await api.get(
            ''.concat(
                `users/sellers?after_date=${after_date}&before_date=${before_date}`,
                filter_level ? `&filter_level=${filter_level}` : '',
                filter_status ? `&filter_status=${filter_status}` : '',
                filter_name ? `&filter_name=${filter_name}` : '',
                // cursor ? `&after_cursor=${cursor}` : ''
            ),
          getAuthHeader(token)
        );
        return data;
    },
    errorMessage: {
        title: 'Erro na requisição',
        description: 'Não foi possível carregar as vendas',
    },
});

interface AddStudentLogin {
    Request: {
        student_id: string;
        student_login: string;
    };
    Response: void;
}
  
export const useAddStudentLogin = () => useApiCall<AddStudentLogin['Request'], AxiosResponse<AddStudentLogin['Response']>>({
    request: async ({
        student_id,
        student_login,
        }, token) => {
        const res : RawHTTPResponse<AddStudentLogin['Response']> = await api.put(
        '/student/login', {
            student_id,
            student_login,
        }, getAuthHeader(token));
        return res;
    }, errorMessage: {
        title: 'Erro na requisição',
        description: 'Não foi possível atualizar o login',
      },

});

