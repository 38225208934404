import styled from 'styled-components'

interface ITabContainerProps {
    checkout: boolean
}

export const TabContainer = styled.div<ITabContainerProps>`
   background-color: var(--black-background);
    border-radius: 1.3rem;
    color: #fff;
    text-align: center;

    display: grid;
    grid-template-columns: 1fr 1fr;

    width: 29rem;
    height: 2.5rem;
    margin: 0 auto;

    ${({ checkout }) => checkout && {
    border: '1px solid #08D787',
    borderRadius: '1.3rem',
    height: '2.7rem',
  }};
    @media (max-width: 420px) {
        width: 22rem;
        height: 3.2rem;
        ${({ checkout }) => checkout && {
    border: '1px solid #08D787',
    borderRadius: '1.5rem',
    height: '3.4rem',
  }};
    }

    button {
        background: none;
        border: none;

        color: #fff;

        height: 2.5rem;
        padding: 0;
        outline: none;
        cursor: pointer;
        @media (max-width: 420px) {
            font-size: 1.2rem;
            line-height: 1.2rem;

            height: 3.2rem;
        }
    }
    .active {
        background-color: var(--green-primary);
        border-radius: 10px;
        border: none;
        color: var(--black-background);
        font-size: 12.5px;
        line-height: 13px;

        width: 14.8rem;
        padding: .5rem;
        outline: none;
        @media (max-width: 420px) {
            font-size: 1.2rem;
            line-height: 1.2rem;

            width: 11.3rem;
        }
        &:focus {
            outline: none;
            box-shadow: 0 0 0 0.5px var(--green-primary);
        }
    }
`