import styled from 'styled-components'

interface backgroundFixed {
    isBackgroundFixed: boolean
}

export const Background = styled.div <backgroundFixed>`
    background-color: var(--black-background);
    color: #fff;

    max-width: 100vw;
    min-height: 100vh;
    ${({ isBackgroundFixed }) => isBackgroundFixed && {
        minWidth: '100vw',
        position: 'fixed',
        top: '0',
        right: '0'
    }};
    @media (max-width: 1001px) {
        min-width: 100vw;
        max-width: 100vw;
    }
`

export const Container = styled.div`
    display: grid;
    grid-template-columns: 2fr 4fr .5fr;
    justify-content: space-around;
    padding: 52px 0;
    margin: 0 auto;
    @media (max-width: 1001px) {
        padding: 7.2rem 0 5.2rem 0;
    }
    @media (max-width: 600px) {
        padding: 5rem 0 2rem 0;
    }
`

export const Main = styled.main`
    @media (max-width: 1001px) {
        margin: 0 auto;
    }
    img {
        display: block;
        margin: 0 auto 0.4rem;
        @media (max-width: 1001px) {
            max-width: 8rem;
        }
    }
    section {
        background-color: #fff;
        border-radius: 1.4rem;
        color: var(--black-background);

        /* max-width: 1000px; */
        padding: 4rem;
        @media (max-width: 911px) {
            border-radius: 0;
            width: 100vw;
        }

        @media (max-width: 500px) {
            padding: 1rem;
        }
    }
`

export const Table = styled.table`
        border-spacing: 0;

        display: flex;
        flex-direction: column;
        
        position: relative;

        max-width: 100%;
        margin-top: 16px;
    padding-bottom: 8px;
    max-height: 380px;
    overflow: auto;
    @media(max-width: 700px) {
        max-width: 60rem;
    }

    ::-webkit-scrollbar-track {
        background-color: #c4c4c4;
        border-radius: 20px;
    }
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        @media (max-width: 800px) {
            width: 4px;
            height: 4px;
        }
    }
    ::-webkit-scrollbar-thumb {
        background: #646464;
        border-radius: 20px;
    }
    thead tr {
        font-weight: 800;
        font-size: 1.3rem;
        line-height: 1.3rem;
    }
    thead tr,
    tbody tr {
        min-width: 100%;
        @media(max-width: 700px) {
            min-width: 740px;
        }
    }

    /* table tr */
    
    tbody td {
        font-size: 1.2rem;
        line-height: 1.2rem;

        margin: 8px 0;
    } 

`;
