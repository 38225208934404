import React, { useState } from "react";

import { getCurrencyMask } from "../../services/utils/currencyFormatter";
import { format, parseISO } from "date-fns";

import { ISale } from "../../models/Sale";
import { IStudent } from "../../models/Student";
import { ICourse } from "../../models/Course";
import { IUser } from "../../models/User";

import { Table } from "../../styles/GenericStyles";

const Schedules: React.FC<{
  data: ISale[];
  onScroll: (e: React.UIEvent<HTMLElement>) => void;
}> = ({ data, onScroll }) => {
  return (
    <>
      <thead>
        <tr>
          <th>Aluno</th>
          <th>Curso</th>
          <th>Vendedor</th>
          <th>Valor</th>
          <th>Adesão</th>
          <th></th>
        </tr>
      </thead>

      <Table onScroll={onScroll}>
        <tbody>
          {data.map(
            ({ student, course, user, value_of_course, created_at }) => (
              <tr>
                <td>{student.name}</td>
                <td>{course.name}</td>
                <td>{user.name}</td>
                <td>{getCurrencyMask(value_of_course)}</td>
                <td>{format(parseISO(created_at), "dd/MM/yyyy")}</td>
              </tr>
            )
          )}
        </tbody>
      </Table>
    </>
  );
};

export default Schedules;
