export interface ICourse {
    id: string;
    name: string;
    description: string;
    price: number;
    promotional_price: number;
    category: CourseCategoryEnum;
    is_available: boolean;
    is_active: boolean;
    picture: string;
    created_at: string;
    updated_at: string;
};

export enum CourseCategoryEnum {
    Grad = 'Graduação',
    PostGrad = 'Pós Graduação'
};

export type ICourseBody = Omit<ICourse, 'is_available' | 'is_active' | 'created_at' | 'updated_at'>

export const emptyCourse: ICourseBody = {
    id: '',
    name: '',
    description: '',
    price: 0,
    promotional_price: 0,
    picture: '',
    category: CourseCategoryEnum.Grad,
};