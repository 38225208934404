import React, { useEffect, useState } from "react";

import Input from "../../components/Input";

import Schedules from "./Schedules";
import Paid from "./Paid";

import { SchedulesAndPaidContainer } from "./styles";
import DatePicker from "../../components/DatePicker";
import { useGetPaidSales } from "../../services/api/sales";
import useDataPaginator from "../../hooks/dataPaginator";
import { ISale } from "../../models/Sale";
import useDebouncedText from "../../hooks/debouncedText";
import { formatCurrency } from "../../services/utils/format";
import { getRawCurrency } from "../../utils/currencyFormatter";
import { getCurrencyMask } from "../../services/utils/currencyFormatter";

interface ISchedulesAndPaidProps {
  isSelect: boolean;
}

const SchedulesAndPaid: React.FC<ISchedulesAndPaidProps> = ({ isSelect }) => {
  const [dateRange, setDateRange] = useState<Date[]>([
    new Date(new Date().setDate(new Date().getDate() - 15)),
    new Date(new Date().setDate(new Date().getDate() + 1)),
  ]);
  const getPaidSales = useGetPaidSales();

  const [filterCourseName, setFilterCourseName] = useDebouncedText("");
  const [filterName, setFilterName] = useDebouncedText("");
  const [filterSellerName, setFilterSellerName] = useDebouncedText("");
  const [filterValue, setFilterValue] = useDebouncedText("");
  const [filterWithoutLogin, setFilterWithoutLogin] = useState("");

  const [
    data,
    setData,
    dataListLoading,
    checkDataListBottom,
  ] = useDataPaginator<
    ISale,
    [Date[], string, string, string, string, string, boolean]
  >({
    filters: [
      dateRange,
      filterCourseName,
      filterName,
      filterSellerName,
      filterValue,
      filterWithoutLogin,
      isSelect,
    ],
    storeNewData: async (filters, cursor) => {
      const res = await getPaidSales({
        cursor: cursor as string,
        filter_course_name: filters[1],
        filter_name: filters[2],
        filter_seller_name: filters[3],
        filter_value: parseInt(filters[4]),
        without_login: !!filters[5],
        paid: !filters[6],
        after_date: filters[0][0].toISOString(),
        before_date: filters[0][1].toISOString(),
      });
      console.log("---<<>>", res);
      return {
        nextCursor: res.cursor.afterCursor,
        shouldResetData: !res.cursor.beforeCursor,
        nextData: res.data,
      };
    },
  });
  useEffect(() => {
    console.log("");
  }, []);
  return (
    <SchedulesAndPaidContainer selected={isSelect ? "Scheduled" : "Paid"}>
      {isSelect ? (
        <div className="value-center">
          {/* <span className="span">{getCurrencyMask(totalAmount | 0)}</span> */}
        </div>
      ) : (
        <div className="value-center">
          {/* <span className="span">R$ 5.800,00</span> */}
        </div>
      )}
      <div className="filters-container">
        <Input
          type="text"
          placeholder="Nome"
          onChange={(e) => setFilterName(e.target.value)}
        />
        <Input
          type="text"
          placeholder="Curso"
          onChange={(e) => setFilterCourseName(e.target.value)}
        />
        <Input
          type="text"
          placeholder="Vendedor"
          onChange={(e) => setFilterSellerName(e.target.value)}
        />
        {!isSelect && (
          <button
            type="button"
            id={filterWithoutLogin}
            className="span"
            onClick={() => {
              if (filterWithoutLogin) {
                setFilterWithoutLogin("");
              } else {
                setFilterWithoutLogin("buttonOn");
              }
            }}
          >
            Sem Login
          </button>
        )}
        <DatePicker dateRange={dateRange} setDateRange={setDateRange} />
      </div>
      {isSelect ? (
        <Schedules onScroll={checkDataListBottom} data={data} />
      ) : (
        <Paid onScroll={checkDataListBottom} data={data} />
      )}
    </SchedulesAndPaidContainer>
  );
};

export default SchedulesAndPaid;
