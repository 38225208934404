import React, { useState } from 'react';

import Input from '../../components/Input';
import cancel from '../../Assets/icons/cancel.svg';
import ok from '../../Assets/icons/ok.svg';
import more from '../../Assets/icons/button-more.svg';
// import down from '../../Assets/icons/button-down.svg';

import { getCurrencyMask, getRawCurrency } from '../../utils/currencyFormatter';

import useDebouncedText  from '../../hooks/debouncedText';
import { useToast }  from '../../hooks/toast';
import useDataPaginator  from '../../hooks/dataPaginator';

import { useListStudentsPayments, useCreateStudentsPayments } from '../../services/api';

import { Table } from '../../styles/GenericStyles';

import DatePicker from '../../components/DatePicker';

import { subDays } from 'date-fns';

interface IStudentsProps {
    isAdd: boolean
    setIsAdd: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IPaymentInput {
    id: string
    login: string
    value: number
    month: string
}

type IValidPaymentInputs = {
    prop: 'login' | 'month';
    value: string;
} | { prop: 'value'; value: number };

interface IPayment {
    id: string
    name: string
    login: string
    course: string
    price: number
    month: string
}

const dummyData: IPayment[] = [
    {
        id: "as12dwddasd",
        name: "Lucas",
        login: "lucas123",
        course: "Gestão Comercial",
        price: 56000,
        month: "Setembro",
    },
    {
        id: "asdg354ghasd",
        name: "Lucas",
        login: "lucas123",
        course: "Gestão Comercial",
        price: 56000,
        month: "Setembro",
    },
    {
        id: "asdafcwe12sd",
        name: "Lucas",
        login: "lucas123",
        course: "Gestão Comercial",
        price: 56000,
        month: "Setembro",
    },
    {
        id: "asd423asd2",
        name: "Lucas",
        login: "lucas123",
        course: "Gestão Comercial",
        price: 56000,
        month: "Setembro",
    }
];

const Students: React.FC<IStudentsProps> = ({ isAdd, setIsAdd }) => {
    const listStudentPayments = useListStudentsPayments();
    const createStudentsPayments = useCreateStudentsPayments();
    
    const { addToast } = useToast();
    
    const [registerDateFilter, setRegisterDateFilter] = useState<Date[]>([
        subDays(new Date(), 30),
        new Date()
    ]);

    const [totalValue, setTotalValue] = useState<number>(677800);
    const [nameFilter, setNameFilter] = useDebouncedText();
    const [paymentInputs, setPaymentInputs] = useState<IPaymentInput[]>([]);
    const [
        payments,
        setPayments,
        arePaymentsLoading,
        checkScrollBottom
    ] = useDataPaginator<IPayment, [string, Date[]]>({
        filters: [nameFilter, registerDateFilter],
        storeNewData: async (filters, cursor) => {
            const res = await listStudentPayments({
                cursor: cursor as string,
                nameFilter: filters[0],
                afterDate: filters[1][0],
                beforeDate: filters[1][1]
            });

            return {
                nextCursor: res.cursor.beforeCursor === res.cursor.afterCursor ? null : res.cursor.afterCursor,
                shouldResetData: !res.cursor.beforeCursor,
                nextData: res.data.map(sp => ({
                    id: sp.id,
                    name: sp.student.name,
                    login: sp.student.login || '',
                    course: sp.sale.course.name,
                    price: sp.value,
                    month: sp.payment_month
                }))
            }
        }
    });

    // const [payments, setPayments] = useState<IPayment[]>(dummyData);

    function handleAddInput() {
        setPaymentInputs((currentInputs) =>
            [
                ...currentInputs,
                {
                    id: Date.now().toString(),
                    login: '',
                    value: 0,
                    month: ''
                }
            ]
        );
    };

    const updatePaymentInput = (id: string, update: IValidPaymentInputs) => {
        setPaymentInputs((inps) => inps.map(inp => {
            if (inp.id === id) {
                return {
                    ...inp,
                    [update.prop]: update.value
                };
            } else return inp;
        }));
    };

    const submitUpdates = async () => {
        for (const inp of paymentInputs) {
            if (inp.login.length < 3) {
                addToast({
                    type: 'error',
                    title: 'Entrada pendente',
                    description: 'Login deve ter no mínimo 3 caracteres'
                });
                return;
            }

            if (inp.value === 0) {
                addToast({
                    type: 'error',
                    title: 'Entrada pendente',
                    description: 'Coloque o valor do pagamento'
                });
                return;
            }

            if (inp.month.length === 0) {
                addToast({
                    type: 'error',
                    title: 'Entrada pendente',
                    description: 'Selecione o mês'
                });
                return;
            }
        };
        
        const cleanTuitions = paymentInputs.map(pi => ({
            login: pi.login,
            value: pi.value,
            tuition_month: pi.month,
        }));

        try {
            const res = await createStudentsPayments(cleanTuitions);
            setPayments(ps => [
                ...res.map(newPays => ({
                    id: newPays.id,
                    name: newPays.student.name,
                    login: newPays.student.login || '',
                    course: newPays.sale.course.name,
                    price: newPays.value,
                    month: newPays.payment_month
                })),
                ...ps
            ]);
            disableAddMode();
        } catch (error) {}
    };

    const disableAddMode = () => {
        setIsAdd(false);
        setPaymentInputs([]);
    };

    return (
        <>
            <div className="value-center">
                <span>{getCurrencyMask(totalValue)}</span>
            </div>
            <div className="filters-container-students">
                <Input
                    type="text"
                    placeholder="Nome"
                    onChange={(e) => setNameFilter(e.target.value)}
                />
                <DatePicker
                    dateRange={registerDateFilter}
                    setDateRange={setRegisterDateFilter}
                />

                {isAdd ?
                    <div className="button-right">
                        <button
                            type="button"
                            id="ok"
                            onClick={submitUpdates}
                        >
                            <img src={ok} alt="Ok" />
                        </button>

                        <button type="button" id="cancel" onClick={disableAddMode}>
                            <img src={cancel} alt="Cancel" />
                        </button>
                    </div>
                    :
                    <button
                        type="button"
                        className="button-right"
                        onClick={() => {
                            setIsAdd(true);
                            handleAddInput();
                        }}
                    >Adicionar Pagamentos</button>
                }
            </div>
            <Table onScroll={checkScrollBottom} style={{ maxHeight: '300px' }}>
                <thead className="tr-students">
                    <tr>
                        <th>Aluno</th>
                        <th>Login</th>
                        <th>Curso</th>
                        <th>Valor</th>
                        <th>Mês</th>
                    </tr>
                    {isAdd &&
                        <tr className="new-student">
                            <div />
                            <p>Insira o Login</p>
                            <div />
                            <p>Insira o Valor</p>
                            <p className="month">Mês
                                <button type="button" onClick={handleAddInput}>
                                    <img src={more} alt="Button More" />
                                </button>
                            </p>
                        </tr>
                    }
                </thead>
                <tbody className="tr-students">
                    {paymentInputs.map((item) =>
                        <tr className="input-new-student" key={item.id}>
                            <div />
                            <input
                                type="text"
                                placeholder="Login"
                                onChange={(e) => updatePaymentInput(
                                    item.id,
                                    { prop: 'login', value: e.target.value }
                                )}
                            />
                            <div />
                            <input
                                type="text"
                                placeholder="Valor"
                                value={getCurrencyMask(item.value)}
                                onChange={(e) => updatePaymentInput(
                                    item.id,
                                    { prop: 'value', value: getRawCurrency(e.target.value) }
                                )}
                            />
                            {/* <input placeholder="Mês" className="month" /> */}
                            <select
                                id="mes"
                                defaultValue="MONTH_HEAD"
                                onChange={(e) => updatePaymentInput(
                                    item.id,
                                    { prop: 'month', value: e.target.value }
                                )}
                            >
                                <option value="MONTH_HEAD" disabled>Mês</option>
                                <option value="Janeiro">Janeiro</option>
                                <option value="Fevereiro">Fevereiro</option>
                                <option value="Março">Março</option>
                                <option value="Abril">Abril</option>
                                <option value="Maio">Maio</option>
                                <option value="Junho">Junho</option>
                                <option value="Julho">Julho</option>
                                <option value="Agosto">Agosto</option>
                                <option value="Setembro">Setembro</option>
                                <option value="Outubro">Outubro</option>
                                <option value="Novembro">Novembro</option>
                                <option value="Dezembro">Dezembro</option>
                            </select>
                        </tr>
                    )}
                    {payments.map((item) =>
                        <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.login}</td>
                            <td>{item.course}</td>
                            <td>{getCurrencyMask(item.price)}</td>
                            <td>{item.month}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    );
};

export default Students;
