import { ILocalStorageUser } from '../../../models/User';
import useApiCall from '../../../hooks/apiCall';
import api, { RawHTTPResponse } from '../api';

interface Login {
  Request: {
    email: string;
    password: string;
  };
  Response: {
    user: ILocalStorageUser;
    token: string;
  }
}

export const useLogin = () => useApiCall<Login['Request'], Login['Response']>({
  request: async ({ email, password }) => {
    const res: RawHTTPResponse<Login['Response']> = await api.post('/sessions', {
      email,
      password,
    });
    if (res.data.user.profile_role !== 'Admin') {
      throw new Error();
    }
    return res.data;
  },
  errorMessage: 'Erro ao realizar login',
});