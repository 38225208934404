import styled from 'styled-components';

export const DatePickerContainer = styled.div`
  .react-daterange-picker__wrapper {
    background-color: var(--green-primary);
    border: 1px solid var(--green-primary);
    border-radius: 1rem;
    color: var(--black-background);
    font-size: 1.1rem;

    display: flex;

    min-width: 16rem !important;
    max-width: 20rem !important;
    min-height: 24px;
    max-height: 24px;
    min-width: 100%;
    padding: .1rem;
    margin: .4rem;
    @media (max-width: 600px) {
        min-width: 140px !important;
        max-width: 140px !important;
        min-height: 3.4rem !important;
        max-height: 3.4rem !important;
    }
    @media (max-width: 370px) {
        min-width: 132px !important;
        max-width: 132px !important;
    }
    input, button {
      border: none;
      outline: none;
    }
  }
  .react-daterange-picker__inputGroup {
    max-width: 8rem;
    min-width: 6rem;
    border: none;
  }
  .react-daterange-picker__inputGroup__leadingZero {
    margin: 0;
    padding: 0 3px;
  }

  .react-daterange-picker__inputGroup__year{
    text-align: center;

    max-width: 3rem !important;
    width: 100%;
    max-height: 100%;
    min-height: 100%;
    padding: 2px;
  }
  .react-daterange-picker__inputGroup__day,
  .react-daterange-picker__inputGroup__month
 {
    text-align: center;

    max-width: 1.5rem !important;
    width: 100%;
    max-height: 100%;
    min-height: 100%;
    padding: 2px;
  }
  /* .react-daterange-picker__inputGroup__year {
    display: none;
  } */
  .react-daterange-picker__range-divider {
    max-height: 100%;
    min-height: 100%;

    padding: 4px 4px 4px 0;
    @media (max-width: 600px) {
      padding: 12px 2px 4px;
    }

  }
  .react-daterange-picker__clear-button {
    display: none;
  }
  .react-daterange-picker__inputGroup__input {
    min-width: .5rem;
    max-width: 1rem;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  input[type=number]::-moz-inner-spin-button,
  input[type=number]::-moz-outer-spin-button {
    -moz-appearance: none;
  }
  input[type=number]::-moz-inner-spin-button,
  input[type=number]::-moz-outer-spin-button {
    -moz-appearance: none;
  }
  input[type=number]::-ms-inner-spin-button,
  input[type=number]::-ms-outer-spin-button {
    -ms-appearance: none;
  }
  .react-daterange-picker span div {
    top: 2.6rem !important;
    left: 0 !important;
    @media (max-width: 600px) {
      top: 3.1rem !important;
    }
  }
  .react-daterange-picker__calendar {
    width: 16.6rem;
  }

  .react-calendar__navigation {
    margin: 0;
  }
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }
  .react-calendar__navigation button {
    padding: 0;
    margin: 0;
    background-color: var(--green-primary) !important;
    border-radius: 1rem;
    &:hover {
      background-color: rgba(20, 27, 35, 0.5) !important;
    }
  }
  .react-calendar__navigation__arrow button {
    padding: 0;
    margin: 0;
    background-color: var(--green-primary);
  }
  .react-calendar__navigation__next-button,
  .react-calendar__navigation__prev-button {
    padding: 0;
    margin: 0;
    background-color: var(--green-primary) !important;
    border-radius: 1rem;
    &:hover {
      background-color: rgba(20, 27, 35, 0.5) !important;
    }
  }
  .react-calendar {
    background-color: var(--green-primary);
    border: 1px solid var(--green-primary);
    border-radius: 1rem 1rem;

    font-size: 1rem;
  }
  .react-calendar__navigation__label {
    font-size: 1rem;
    font-weight: 800;
    &:hover {
      background-color: rgba(20, 27, 35, 0.5) !important;
      border-radius: 1rem;
    }
    &:active {
      background-color: rgba(20, 27, 35, 0.5) !important;
      border-radius: 1rem;
    }
  }
  .react-calendar__month-view__days__day {
    background-color: var(--green-primary) !important;
    max-width: 1.9rem !important;
    min-width: 1.9rem !important;
    max-height: 1.9rem !important;
    min-height: 1.9rem !important;
    margin: 2px !important;
    padding: 5.5px 2.5px 3px 3px !important;
    font-family: Object Sans;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    &:hover {
      background-color: rgba(20, 27, 35, 0.5) !important;
      border-radius: 50%;
    }
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: var(--black-background);
  }
  .react-calendar__month-view__days__day--weekend {
    color: var(--black-background);
  }
  .react-calendar__tile--active {
    background-color: var(--black-background) !important;
    border-radius: 100%;
    color: #fff;
  }
  .react-calendar__tile--hover {
    background-color: var(--black-background) !important;
    border-radius: 100%;
    color: #fff;
  }
  .react-calendar__tile {
    padding: 1rem;
    margin: 0;
  }
  .react-calendar__year-view__months {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-around;
    button {
      border-radius: 1rem;
      font-size: 10px;
      font-weight: 600;

      width: 100%;
      margin: .1rem 1rem !important;
      padding: .8rem .3rem !important;
      &:hover {
        background-color: var(--green-primary) !important;
        background-color: rgba(20, 27, 35, 0.5) !important;
      }
    }
  }
  .react-calendar__tile--hasActive {
    background-color: var(--black-background) !important;
    color: #fff;
    &:hover {
      background-color: rgba(20, 27, 35, 0.5) !important;

    }
  }
  .react-calendar__decade-view__years {
    button {
      background-color: var(--green-primary) !important;
      &:hover {
        background-color: rgba(20, 27, 35, 0.5) !important;
        border-radius: 1rem;
      }
    }
    .react-calendar__tile--hasActive {
      background-color: var(--black-background) !important;
      border-radius: 1rem;
      color: #fff;
      &:hover {
        background-color: rgba(20, 27, 35, 0.5) !important;
      }
    }
  }
`;
