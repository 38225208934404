import React, { useCallback, useEffect, useState, useRef } from "react";
import * as Yup from "yup";

import close from "../../Assets/icons/close.svg";

import { ModalContainer, InputPhoto } from "./styles";
import { ITrainingVideoBody, emptyVideo } from "../../models/TrainingVideo";
import { useToast } from "../../hooks/toast";
import { getEmbedCode } from "../../utils/youtubeEmbed";
import { useDeleteVideo } from "../../services/api/videos";
import { isDate } from "date-fns";

interface IModal {
  isOpenModal: boolean;
  onRequestClose: () => void;
  thisVideo: ITrainingVideoBody | undefined;
  handleVideo: (video: ITrainingVideoBody, imgFile: File) => Promise<boolean>;
}

const Modal: React.FC<IModal> = ({
  isOpenModal,
  onRequestClose,
  handleVideo,
  thisVideo,
}) => {
  const [imgFile, setImgFile] = useState<File>(new File([], ""));
  const [imgSource, setImgSource] = useState<string>("");
  const [video, setVideo] = useState<ITrainingVideoBody>(
    thisVideo || emptyVideo
  );
  const formRef = useRef<HTMLFormElement>(null);

  const deleteVideo = useDeleteVideo();

  const { addToast } = useToast();
  // console.log({ imgFile })
  // console.log({ imgSource })
  // console.log({ video })

  useEffect(() => {
    setVideo(thisVideo || emptyVideo);
    setImgSource(thisVideo?.picture || "");
    setImgFile(new File([], ""));
  }, [thisVideo]);

  function handleClick() {
    const inputId = document.getElementById("files");
    inputId?.click();
  }

  function handleInputFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target!.result;
        setImgSource(result as string);
        setVideo((currentVideo) => ({
          ...currentVideo,
          picture: result as string,
        }));
      };
      reader.readAsDataURL(event.target.files[0]);
      setImgFile(event.target.files[0]);
    }
  }

  function handleCloseModal() {
    onRequestClose();
    setImgSource("");
    setImgFile(new File([], ""));
    formRef && formRef.current && formRef.current.reset();
  }

  const handleDeletion = async () => {
    if (thisVideo?.id) {
      try {
        const courseSaved = await deleteVideo({ id: thisVideo.id }); //fazer verificação
        if (courseSaved) {
          handleCloseModal();
        }
      } catch (error) {}
    }
  };

  const validateImgFile = (img: File) =>
    Number(img.size) <= 3000000 && img.type.startsWith("image");

  const addNewVideo = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      try {
        const schema = Yup.object().shape({
          picture: Yup.string().required(" A imagem do video é obrigatória"),
          title: Yup.string().required(" O nome do video é obrigatório"),
          link_url: Yup.string()
            .required(" O link do video é obrigatório")
            .test(
              "embed",
              "O link está incorreto",
              (val) => val !== "https://www.youtube.com/embed/undefined"
            ),
          set_home_page_date: Yup.date().nullable(),
          description: Yup.string().required(
            " A descrição do video é obrigatório"
          ),
        });
        await schema.validate(video, { abortEarly: false });

        const isValidImageSelected =
          video.picture.length > 0 &&
          (video.id.length > 0
            ? imgFile.name.length === 0 || validateImgFile(imgFile)
            : validateImgFile(imgFile));

        if (!isValidImageSelected) {
          addToast({
            type: "error",
            title: "Erro nos dados informados",
            description: "Selecione uma imagem válida (máx. 3MB)",
          });
          return;
        }

        const videoSaved = await handleVideo(video, imgFile); //fazer verificação

        if (videoSaved) {
          handleCloseModal();
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          addToast({
            type: "error",
            title: "Ocorreu um erro",
            description: `${error.errors[0]}`,
          });
          return;
        }
      }
    },
    [video, onRequestClose]
  );

  return (
    <ModalContainer isOpenModal={isOpenModal} className="animeTop">
      <button id="close" onClick={handleCloseModal}>
        <img src={close} alt="fechar modal" />
      </button>
      <form ref={formRef} onSubmit={addNewVideo}>
        <div className="modal-container">
          <fieldset className="grid-left">
            <InputPhoto imgSource={imgSource}>
              <button
                type="button"
                onClick={handleClick}
                className="background-image"
              >
                {!imgSource && (
                  <label>
                    Clique aqui
                    <p>Para adicionar uma imagem de capa.</p>
                  </label>
                )}
                <input
                  type="file"
                  name="files"
                  id="files"
                  onChange={handleInputFileChange}
                  hidden
                />
              </button>
            </InputPhoto>
            <div className="description-container">
              <label htmlFor="description">Descrição</label>
              <textarea
                id="description"
                name="description"
                placeholder="Digite aqui a descrição do video"
                value={video.description}
                onChange={(e) =>
                  setVideo((c) => ({ ...c, description: e.target.value }))
                }
              />
            </div>
          </fieldset>
          <div className="grid-right">
            <fieldset>
              <div className="input-data">
                <label>Título</label>
                <input
                  type="text"
                  value={video.title}
                  onChange={(e) =>
                    setVideo((c) => ({ ...c, title: e.target.value }))
                  }
                />
              </div>
              <div className="input-data">
                <label>Categoria</label>
                <input
                  type="text"
                  value={video.category}
                  onChange={(e) =>
                    setVideo((c) => ({ ...c, category: e.target.value }))
                  }
                />
              </div>
              <div className="input-data">
                <label>Link Youtube</label>
                <input
                  type="text"
                  value={video.link_url}
                  onChange={(e) =>
                    setVideo((c) => ({
                      ...c,
                      link_url: `${getEmbedCode(e.target.value)}`,
                    }))
                  }
                />
              </div>
              <div className="input-data">
                <label>Definir como principal?</label>
                <select
                  onChange={(e) => {
                    setVideo((c) => ({
                      ...c,
                      set_home_page_date: e.target.value,
                    }));
                  }}
                >
                  <option value={""}>Não</option>
                  <option value={new Date().toDateString()}>Sim</option>
                </select>
              </div>
            </fieldset>
            {thisVideo?.id && (
              <button
                type="button"
                onClick={() => handleDeletion()}
                className="delete"
              >
                Deletar
              </button>
            )}
            <button type="submit" className="confirm">
              {thisVideo ? "Atualizar" : "Confirmar"}
            </button>
          </div>
        </div>
      </form>
    </ModalContainer>
  );
};

export default Modal;
