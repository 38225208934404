import React from 'react';
import logo from '../../Assets/logo.png';

import { LoginContainer } from './styles';
import { useAuth } from '../../hooks/auth';

const Login: React.FC = () => {
    const { signIn } = useAuth();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const form = e.target as HTMLFormElement;
        const email = form.querySelector<HTMLInputElement>('input[type=email]')?.value as string;
        const password = form.querySelector<HTMLInputElement>('input[type=password]')?.value as string;

        try {
            await signIn({ email, password });
        } catch (error) {
            console.log({ loginErr: error });
        }
    };
    
    return (
        <LoginContainer>
            <img src={logo} width="152" height="76" alt="Logo Fael" className="animeTop" />
            <form className="animeTop" onSubmit={handleSubmit}>
                <input type="email" placeholder="Digite seu e-mail" />
                <input type="password" placeholder="Senha" />
                <button type="submit">Entrar</button>
            </form>
        </LoginContainer>
    );
};

export default Login;
