import styled from 'styled-components'
import search from '../../Assets/icons/search.svg'

export const InputContainer = styled.input`
    background-color: var(--black-background);
    border: 2px solid var(--green-primary);
    border-radius: 1rem;
    color: #fff;

    font-size: 1.3rem;
    line-height: 2.2rem;
    
    max-width: 16.8rem;
    max-height: 3.6rem;
    padding: 0.8rem 0.4rem;
    &::placeholder {
        background: url(${search}) no-repeat;
        background-repeat: no-repeat;
        background-position: right;
        font-weight: 800;
        font-size: 1.2rem;
        line-height: 2.2rem;
        
        display: flex;
        justify-content: space-between;
    }
    &:hover,
    &:focus {
        outline: none;
        box-shadow: 0 0 0 1.5px var(--green-primary);
    }
    &[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    @media(max-width: 600px) {
        max-width: 15rem;
        max-height: 4rem;
    }
    @media(max-width: 420px) {
        max-width: 12.5rem;
    }
    @media(max-width: 380px) {
        max-width: 14rem;
    }
`
