import styled from 'styled-components'

interface ISelectContainerProps {
    thisForm: boolean
}

export const SelectContainer = styled.select<ISelectContainerProps>`
    background: var(--black-background);
    border: ${props => props.thisForm ? '1px' : '2px'} solid var(--green-primary);
    border-radius: 10px;
    color: #fff;

    height: 4rem;
    max-height: ${props => props.thisForm ? '4rem' : '3.6rem'};
    padding-left: .4rem;
    margin-bottom: ${props => props.thisForm ? '1rem' : '0'};
    outline: none;
    @media(max-width: 600px) {
        min-height: 4rem;
        max-height: 4rem;
        padding: 1rem 0 .6rem .4rem; 
    }
    &:hover,
    &:focus {
        outline: none;
        box-shadow: 0 0 0 1px var(--green-primary);
    }
`
