import styled from 'styled-components'

export const AccordionContainer = styled.div`
    button {
        color: var(--black-background);
        font-family: Object Sans;
        font-size: 1.5rem;
        line-height: 1.8rem;

        min-height: 4rem;
        p {
            padding-top: 0.3rem;
        }
        @media (max-width: 600px) {
            font-size: 1.4rem;
            line-height: 1.7rem;
        }
    }
    .accordion-content {
        overflow: hidden;
        transition: max-height 0.6s ease;
    }
    .accordion {
        background-color: transparent;
        border: 1px solid var(--black-background);
        border-radius: 1rem;
        text-align: left;

        width: 100%;
        padding: 1rem;
        margin: 0.8rem 0;
        cursor: pointer;
        outline: none;
    }
    .accordion-text {
        color: var(--black-background);
        font-size: 1.4rem;
        line-height: 1.8rem;

        margin: 0.8rem;
        @media (max-width: 600px) {
            font-size: 1.3rem;
            line-height: 1.7rem;
        }
    }
`