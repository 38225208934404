import React from 'react';
import { NavLink } from 'react-router-dom';
import { bool, func } from 'prop-types';

import home from '../../../Assets/icons/Home.svg';
import dinheiro from '../../../Assets/icons/dinheiro.svg';
import capelo from '../../../Assets/icons/capelo.svg';
import vendas from '../../../Assets/icons/vendas.svg';
import compliance from '../../../Assets/icons/compliance.svg';
import duvidas from '../../../Assets/icons/duvidas.svg';
import cumprimento from '../../../Assets/icons/cumprimento.svg';

import { Nav } from './styles';

interface IMenuProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Menu: React.FC<IMenuProps> = ({ open, setOpen }) => {
  return (
    <Nav open={open}>
      <NavLink
        to="/home"
        activeClassName="active"
        className="nav-link"
        onClick={() => setOpen(!open)}
      >
        <img src={home} alt="Home" />
        <span>Home</span>
      </NavLink>
      <NavLink
        to="/financial"
        activeClassName="active"
        className="nav-link"
        onClick={() => setOpen(!open)}
      >
        <img src={dinheiro} alt="financeiro" />
        <span>Financeiro</span>
      </NavLink>
      <NavLink
        to="/course"
        activeClassName="active"
        className="nav-link"
        onClick={() => setOpen(!open)}
      >
        <img src={capelo} alt="Cursos" />
        <span>Cursos</span>
      </NavLink>
      <NavLink
        to="/client"
        activeClassName="active"
        className="nav-link"
        onClick={() => setOpen(!open)}
      >
        <img src={cumprimento} alt="Clientes" />
        <span>Clientes</span>
      </NavLink>
      <NavLink
        to="/compliance"
        activeClassName="active"
        className="nav-link"
        onClick={() => setOpen(!open)}
      >
        <img src={compliance} alt="Aprovação" />
        <span>Aprovação</span>
      </NavLink>
      <NavLink
        to="/faq"
        activeClassName="active"
        className="nav-link"
        onClick={() => setOpen(!open)}
      >
        <img src={duvidas} alt="Central de Ajuda" />
        <span>FAQ</span>
      </NavLink>
      <NavLink
        to="/training"
        activeClassName="active"
        className="nav-link"
        onClick={() => setOpen(!open)}
      >
        <img src={vendas} alt="Treinamento" />
        <span>Treinamento</span>
      </NavLink>
    </Nav>
  );
};

Menu.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Menu;
