import styled from 'styled-components';

export const LoginContainer = styled.div`
    background-color: #141B23;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-width: 100vw;
    min-height: 100vh;
    img {
        margin-bottom: 5.4rem;
    }
    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        input {
            border: none;
            background: rgba(196, 196, 196, 0.2);
            border-radius: 1.5rem;
            color: #fff;
            font-size: 1.4rem;
            line-height: 1.4rem;
            text-align: center;

            width: 33rem;
            height: 5.4rem;
            padding: 0 .8rem;
            margin-bottom: .8rem;
            transition: 200ms;
            &::placeholder {
                color: #fff;
                font-size: 1.5rem;
                line-height: 1.5rem;
                text-align: center;
            }
        }
        input:hover,
        input:focus {
            outline: none;
            box-shadow: 0 0 0 2px var(--green-primary);
        }
        button {
            border: none;
            background-color: var(--green-primary);
            border-radius: 3.7rem;
            font-size: 1.4rem;

            width: 27.1rem;
            height: 5.4rem;
            margin-top: .2rem;
            padding: 1rem 0 .6rem;
            cursor: pointer;
            outline: none;
        }
        @media (max-width: 350px) {
            input {
                width: 29rem;
                height: 4rem;
            }
            button {
                height: 4rem;
            }
        }
    }
`;
