import React, { useState, useEffect } from 'react';

type Cursor = string | null;

interface DataPaginatorParams<T, S extends any[]> {
    filters: S;
    storeNewData: (filters: S, cursor: Cursor) => Promise<{
        nextCursor: Cursor,
        shouldResetData: boolean,
        nextData: T[]
    }>,
    onError?: () => void,
    initialData?: T[]
};

type DataPaginatorReturn<T> = [
    T[],
    React.Dispatch<React.SetStateAction<T[]>>,
    boolean,
    (event: React.UIEvent<HTMLElement, UIEvent>) => void
];

type DataPaginatorHook = <T, S extends any[]>(params: DataPaginatorParams<T, S>) => DataPaginatorReturn<T>;

const useDataPaginator: DataPaginatorHook = <T, S extends any[]>({
    filters,
    storeNewData,
    onError,
    initialData = []
}: DataPaginatorParams<T, S>) => {
    const [data, setData] = useState<T[]>(initialData);
    const [pageCursor, setPageCursor] = useState<Cursor>("");
    const [shouldLoadMoreData, setShouldLoadMoreData] = useState<boolean>(false);
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

    const checkScrollBottom = (event: React.UIEvent<HTMLElement, UIEvent>) => {
        const {
            scrollHeight,
            scrollTop,
            clientHeight,
        } = event.currentTarget;
    
        const bottom = scrollHeight - scrollTop < clientHeight + 10;
        if (bottom) {
            setShouldLoadMoreData(true);
        } else {
            setShouldLoadMoreData(false);
        }
    };

    const loadMoreData = async (cursor: string | null = null) => {
        if (!isLoadingData) {   
            setIsLoadingData(true);

            try {
                const {
                    nextCursor,
                    shouldResetData,
                    nextData
                } = await storeNewData(filters, cursor);

                if (shouldResetData) {
                    setData(nextData);
                } else {
                    setData(currentData => [...currentData, ...nextData]);
                }
            
                setPageCursor(nextCursor);
            } catch (error) {
                console.log('Error loading data', { error });
                setPageCursor(null);
                setData([]);
                onError && onError();
            } finally {
                setIsLoadingData(false);
            }
        }
    };

    useEffect(() => {
        if (shouldLoadMoreData && pageCursor !== null) {
            loadMoreData(pageCursor);
        }
    }, [shouldLoadMoreData, pageCursor]);

    useEffect(() => {
        loadMoreData();
    }, [...filters]);
    
    return [data, setData, isLoadingData, checkScrollBottom];
};

export default useDataPaginator;