import api, { RawHTTPResponse, getAuthHeader, PaginatedResponse } from '../api';
import useApiCall from '../../../hooks/apiCall';
import { ISale } from '../../../models/Sale';

interface GetPaidSales {
    Request: {
        filter_course_name?: string;
        filter_name?: string;
        filter_seller_name?:string;
        filter_value?: number;
        without_login?: boolean
        paid?: boolean
        before_date: string;
        after_date: string;
        cursor?: string;
    };
    Response: PaginatedResponse<ISale>;
}
  
export const useGetPaidSales = () => useApiCall<GetPaidSales['Request'], GetPaidSales['Response']>({
    request: async ({
        filter_course_name,
        filter_name,
        filter_seller_name,
        filter_value,
        cursor,
        without_login,
        paid,
        before_date,
        after_date}, token) => {
        const { data }: RawHTTPResponse<GetPaidSales['Response']> = await api.get(
            ''.concat(
                `/sales/manager/home?after_date=${after_date}&before_date=${before_date}`,
                paid ? `&paid=${paid}` : '',
                without_login ? `&without_login=${without_login}` : '',
                filter_course_name ? `&filter_course_name=${filter_course_name}` : '',
                filter_name ? `&filter_name=${filter_name}` : '',
                filter_seller_name ? `&filter_seller_name=${filter_seller_name}` : '',
                filter_value ? `&filter_value=${filter_value}` : '',
                cursor ? `&after_cursor=${cursor}` : ''
            ),
          getAuthHeader(token)
        );
        return data;
    },
    errorMessage: {
        title: 'Erro na requisição',
        description: 'Não foi possível carregar as vendas pagas',
    },
});

