import React, { useState } from 'react';

import Input from '../../components/Input';
import { Checkbox } from './checkbox';

import { Table } from '../../styles/GenericStyles';

import DatePicker from '../../components/DatePicker';

import Modal from './Modal'

import { subDays } from 'date-fns';

import {
    IPayment,
    IValidPaymentStatusFilter,
    PaymentStatusEnum,
    translatePaymentStatus
} from '../../models/Payment';

import { formatCurrency } from '../../services/utils/format';

import useDataPaginator from '../../hooks/dataPaginatorSkip';
import useDebouncedText from '../../hooks/debouncedText';
import { useToast } from '../../hooks/toast';

import { useSellersPaymentsList, useUpdateSellersPaymentStatus } from '../../services/api';

interface ISellerPayment {
    id: string;
    name: string;
    bank_completed_name: string | null;
    bank: string | null;
    bank_account: string | null;
    bank_account_type: string | null;
    bank_agency: string | null;
    bank_document_number: string | null;
    balance: string | number;
}

interface ICheckableSellerPayment extends ISellerPayment {
    checked: boolean;
};

const dummyData: ICheckableSellerPayment[] = [
    {
        id: 'abc123',
        name: 'Tião da Silva',
        bank_completed_name: null,
        bank_document_number: '037.569.482-00',
        bank: '655',
        bank_account_type: 'Conta Corrente',
        bank_account: '991222-1',
        bank_agency: '0655',
        balance: 50000,
        checked: false,
    },
    {
        id: 'abc124',
        name: 'Betina Rica',
        bank_completed_name: null,
        bank_document_number: '037.569.482-00',
        bank: '655',
        bank_account_type: 'Poupança',
        bank_account: '991222-1',
        bank_agency: '0655',
        balance: 78900,
        checked: false,
    },
    {
        id: 'azc125',
        name: 'Rivandedor Bello',
        bank_completed_name: null,
        bank_document_number: '037.569.482-00',
        bank: '655',
        bank_account_type: 'Poupança',
        bank_account: '991222-1',
        bank_agency: '0655',
        balance: 78900,
        checked: false,
    },
];

const Salespeople: React.FC = () => {
    const getSellersPaymentsList = useSellersPaymentsList();
    const updateSellersPaymentStatus = useUpdateSellersPaymentStatus();

    const { addToast } = useToast();
    
    const [nameFilter, setNameFilter] = useDebouncedText();
    const [registerDateFilter, setRegisterDateFilter] = useState<Date[]>([
        subDays(new Date(), 30),
        new Date()
    ]);
    const [paymentStatusFilter, setPaymentStatusFilter] = useState<IValidPaymentStatusFilter>(PaymentStatusEnum.no_paid);

    const [totalValue, setTotalValue] = useState<number>(0);

    const [
        payments,
        setPayments,
        arePaymentsLoading,
        checkPaymentsListBottom
    ] = useDataPaginator<ICheckableSellerPayment, [string, IValidPaymentStatusFilter]>({
        initialData: [],
        filters: [nameFilter, paymentStatusFilter],
        storeNewData: async (filters, skip) => {
            const res = await getSellersPaymentsList({ 
                skip,
                nameFilter: filters[0],
                status: filters[1]
            });

            setTotalValue(res.total_earnings);

            return {
                nextSkipCounter: skip + res.sellers.length,
                hasReachedEnd: res.sellers.length === 0,
                nextData: res.sellers.map(p => ({
                    ...p,
                    checked: false
                })),
            };
        }
    });

    const [isNewStatusModalOpen, setIsNewStatusModalOpen] = useState<boolean>(false);
    const [isEditModeActive, setIsEditModeActive] = useState<boolean>(false);

    function enableEditMode() {
        setIsEditModeActive(true);
    }

    function disableEditMode() {
        setIsEditModeActive(false);
        setPayments(ps => ps.map(p => (
            { ...p, checked: false }
        )));
    }

    const handleEditConfirmation = () => {
        if (payments.some(p => p.checked)) {
            setIsNewStatusModalOpen(true);
        } else {
            addToast({
                type: 'error',
                title: 'Ação Inválida',
                description: 'Selecione pelo menos um vendedor',        
            });
        }
    };

    const handleNewStatusConfirmation = async (newStatus: IValidPaymentStatusFilter) => {
        try {
            const sellersList = payments
                .filter(p => p.checked)
                .map(p => ({
                    seller_id: p.id,
                    from_status: paymentStatusFilter,
                    to_status: newStatus
                }));
    
            const res = await updateSellersPaymentStatus(sellersList);
            
            setPayments(ps => ps.map(p => {
                if (p.checked === true) {
                    return { ...p, payment_status: newStatus };
                } else {
                    return p;
                }
            }));
            
            disableEditMode();

            return true;
        } catch (error) {
            return false;
        }
    };

    const updateSellerPaymentCheck = (id: string, checked: boolean) => {
        setPayments(ps => ps.map(p => {
            if (p.id === id) {
                return { ...p, checked }
            } else {
                return p;
            }
        }));
    };

    const getPaymentStatusButtonProps = (status: IValidPaymentStatusFilter): {
        type: "button"; className: string; onClick: () => void;
    } => ({
        type: "button",
        className: paymentStatusFilter === status ? 'selected' : '',
        onClick: () => setPaymentStatusFilter(status)
    });

    const NAWrapper = (value: string | null) => value || <span>&#8709;</span>;

    return (
        <>
            <div className="value-center">
                <span>{formatCurrency(totalValue)}</span>
            </div>
            <div className="filters-container-salespeople">
                <Input
                    type="text"
                    placeholder="Nome"
                    onChange={(e) => setNameFilter(e.target.value)}
                />
                {/* <DatePicker
                    dateRange={registerDateFilter}
                    setDateRange={setRegisterDateFilter}
                /> */}

                <button
                    style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
                    {...getPaymentStatusButtonProps(PaymentStatusEnum.no_paid)}
                >Não Pagas</button>
                <button
                    {...getPaymentStatusButtonProps(PaymentStatusEnum.waiting)}
                >Pendentes</button>
                <button
                    {...getPaymentStatusButtonProps(PaymentStatusEnum.paid)}
                >Pagas</button>
            </div>
            <Table onScroll={checkPaymentsListBottom} style={{ maxHeight: '300px' }}>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>CPF</th>
                        <th>Banco</th>
                        <th>Tipo de Conta</th>
                        <th>Conta</th>
                        <th>Agência</th>
                        <th>Saldo</th>
                        <th>
                            {isEditModeActive ? <>
                                    <button type="button" onClick={handleEditConfirmation}>Alterar</button>
                                    <button type="button" onClick={disableEditMode}>Cancelar</button>
                                </>
                                :
                                <button type="button" onClick={enableEditMode}>Editar</button>
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {payments.map(payment => (
                        <tr key={payment.id}>
                            <td>{NAWrapper(payment.name)}</td>
                            <td>{NAWrapper(payment.bank_document_number)}</td>
                            <td>{NAWrapper(payment.bank)}</td>
                            <td>{NAWrapper(payment.bank_account_type)}</td>
                            <td>{NAWrapper(payment.bank_account)}</td>
                            <td>{NAWrapper(payment.bank_agency)}</td>
                            <td>{formatCurrency(payment.balance)}</td>
                            <td>
                                {isEditModeActive &&
                                    <Checkbox
                                        checked={payment.checked}
                                        onChange={() => {
                                            updateSellerPaymentCheck(payment.id, !payment.checked)
                                        }}
                                    />
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal
                isOpenModal={isNewStatusModalOpen}
                onRequestClose={() => setIsNewStatusModalOpen(false)}
                handleConfirmation={handleNewStatusConfirmation}
                filteredStatus={paymentStatusFilter}
            />
        </>
    )
}

export default Salespeople;
