import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import { ReactComponent as CalendarDown } from '../../Assets/icons/calendar-down.svg';

import { DatePickerContainer } from './styles';

interface IDatePickerProps {
  dateRange: Date[]
  setDateRange: React.Dispatch<React.SetStateAction<Date[]>>
}

const DatePicker: React.FC<IDatePickerProps> = ({ dateRange, setDateRange }) => (
  <DatePickerContainer>
    <DateRangePicker
      onChange={setDateRange}
      value={dateRange}
      dayPlaceholder="Dia"
      monthPlaceholder="Mês"
      yearPlaceHolder="Ano"
      rangeDivider="-"
      format="dd.MM.yy"
      calendarIcon={<CalendarDown />}
    />
  </DatePickerContainer>
);

export default DatePicker;
